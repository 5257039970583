import React from 'react';
import './navbarStyles.scss';

import Search from '@mui/icons-material/Search';
import Notifications from '@mui/icons-material/Notifications';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import setCurrentUser from '../../../../../db/redux/user/user-actions';
import getEnvironement from '../../../../../../environement';

const navBar = ({ currentUser, SetcurrentUser }) => {
  const urlImagePrefix = `${getEnvironement().BACKEND_URL}/uploads/images/users/`;
  const Navigate = useNavigate();

  const logout = () => {
    SetcurrentUser(null);
    Navigate('/auth/login');
  };

  return (
    <nav className="nav-client">
      <div className="title">Assurances</div>
      <div className="options">

        <div className="icons">
          <Search className="icon" />
          <Notifications className="icon" />
        </div>

        <div className="info">
          <div className="name">

            {`${currentUser.first_name} ${currentUser.last_name}`}

          </div>
          <div className="containet-info">
            <div className="image-name">
              <Link to="/acount/dashboard">
                <img
                  src={urlImagePrefix + currentUser.profile_image_filename}
                  alt=""
                />
              </Link>
            </div>
            <div className="dropdown">
              <button disabled type="button" className="btn" onClick={() => Navigate('/acount/dashboard')}> Profil </button>
              <button type="button" className="btn" onClick={logout}> Se déconnecter </button>
            </div>
          </div>
        </div>
      </div>
    </nav>

  );
};

const mapDispatchToProps = (dispatch) => ({
  SetcurrentUser: (user) => dispatch(setCurrentUser(user)),
});

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(navBar);
