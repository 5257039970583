import React from 'react';
import './ButtonStyles.scss';

export default function Button({
  disabled,
  children,
  reverse,
  fullwidth,
  style,
  handelClick,
}) {
  return (
    <button
      disabled={disabled}
      style={style}
      type="submit"
      className={`custome-button ${reverse ? 'reverse' : ''} ${fullwidth ? 'full-width' : ''}`}
      onClick={handelClick}
    >
      {children}
    </button>
  );
}
