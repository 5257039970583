import React from 'react';
import Input from '../../../../components/inputs/Input/InputComponent';
import Checkbox from '../../../../components/inputs/checkboxToggle/checkboxToggleComponent';
import { ReactComponent as Benefic } from '../../../../assets/icons/benefic.svg';

export default function step2() {
  return (
    <div className="step-form-ac">
      <div className="group-3">
        <Input label="Adresse postale" type="text" fullwidth />
        <Input label="Votre profession" type="text" fullwidth />
        <Input label="Date de débit du contrat" type="date" fullwidth />
      </div>
      <div className="option">
        <div className="text">Etes-vous atteint d&apos;une maladie grave?</div>
        <Checkbox className="icon" />
      </div>
      <div className="option">
        <div className="text">Etes-vous atteint d&apos;une infirmité totale ou partielle?</div>
        <Checkbox className="icon" />
      </div>
      <div className="wrapper-text">
        <div className="title">Bénificiare en cas de décés </div>
        <div className="text">
          si vous ne spécifierz les nom et prénom des bénéficiares(s),
          en cas de décées le capital ira aux avant droits selon
        </div>
      </div>
      <div className="wrapper-text-logo">
        <Benefic className="icon" />
        <div className="text"> Bénéficiaire : 1</div>
      </div>
      <div className="group-3">
        <Input label="Prénom :" type="text" fullwidth />
        <Input label="Nom :" type="text" fullwidth />
        <Input label="Date de naissance :" type="date" fullwidth />
      </div>
      <div className="wrapper-text-logo">
        <Benefic className="icon" />
        <div className="text"> Bénéficiaire : 2</div>
      </div>
      <div className="group-3">
        <Input label="Prénom :" type="text" fullwidth />
        <Input label="Nom :" type="text" fullwidth />
        <Input label="Date de naissance :" type="date" fullwidth />
      </div>
      <div className="wrapper-text-logo">
        <Benefic className="icon" />
        <div className="text"> Bénéficiaire : 3</div>
      </div>
      <div className="group-3">
        <Input label="Prénom :" type="text" fullwidth />
        <Input label="Nom :" type="text" fullwidth />
        <Input label="Date de naissance :" type="date" fullwidth />
      </div>
    </div>
  );
}
