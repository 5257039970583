import React from 'react';
import Done from '../../../../assets/images/done-blue.png';
import { ReactComponent as Moto } from '../../../../assets/icons/moto.svg';

export default function Step2() {
  return (
    <div className="step-form-moto">
      <div className="group">
        <label htmlFor="motto" style={{ width: '100%' }}>
          Pendant combien d&apos;années souhaitez-vous assurer votre moto.
          <input type="number" name="" className="fullwidth" min={0} placeholder={0} />
          {/* <select name="souscription[motto]" id="motto" className="fullwidth">
            <option value="">Lorem ipsum dolo</option>
            <option value="1">D1 Sc1 Bicyclettes et Vélomoteurs &lt;= 50 cm3 et &lt;=60 Km/h </option>
            <option value="1">D1 Sc1 Bicyclettes et Vélomoteurs &lt;= 50 cm3 et&gt; 60 Km/h  </option>
            <option value="2">D1 Sc3 Véhicules à 2 Roues Avec Cylindrée entre 50 et 125 cm3  </option>
            <option value="3">D1 Sc4 Véhicules à 3 Roues Avec Cylindrée &lt;= 125 cm3        </option>
            <option value="4">D1 Sc5 Véhicules à 2 Roues Avec Cylindrée &gt; 125 cm3         </option>
            <option value="5">D1 Sc6 Véhicules à 3 Roues Avec Cylindrée &gt; 125 cm3         </option>
          </select> */}
        </label>
      </div>
      <div className="group">
        <div className="wrapper">
          <div className="container-logo">
            <Moto className="logo" />
          </div>
          <div className="title-container">
            <div className="h2">RC</div>
            <div className="name">Résponsabilité civile</div>
          </div>
          <div className="price"> 602.00 Dhs/An </div>
          <img src={Done} alt="" className="icon" />
        </div>
      </div>
    </div>
  );
}
