import React from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import ContactMail from '@mui/icons-material/ContactMail';
import './sidebarStyles.scss';
import { ReactComponent as Overview } from '../../../../assets/icons/overview.svg';
import { ReactComponent as Profile } from '../../../../assets/icons/profile.svg';
import { ReactComponent as Assurances } from '../../../../assets/icons/assurances.svg';
import { ReactComponent as Facturation } from '../../../../assets/icons/facturation.svg';
import { ReactComponent as Contact } from '../../../../assets/icons/contact.svg';
import { ReactComponent as Attachement } from '../../../../assets/icons/attachment.svg';
import setCurrentUser from '../../../../../db/redux/user/user-actions';
import Logo from '../../../../assets/icons/logov2.png';

function Sidebar({ SetcurrentUser, setOpenDrawer }) {
  const Navigate = useNavigate();
  const logout = () => {
    SetcurrentUser(null);
    Navigate('/auth/login');
  };
  return (
    <div className="sidebar">

      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="logo" className="logo-name" />
        </Link>
      </div>

      <div className="nav-links">

        <div className="link-container">
          <NavLink to="/acount/dashboard" className="link " onClick={() => setOpenDrawer(false)}>
            <Overview className="icon" />
            <span className="link-name">Aperçu</span>
          </NavLink>
        </div>

        <div className="link-container">
          <NavLink to="/acount/profile" className="link" onClick={() => setOpenDrawer(false)}>
            <Attachement className="icon" />
            <span className="link-name">Pièces jointes</span>
          </NavLink>
        </div>
        <div className="link-container">
          <NavLink to="/acount/assurances" className="link" onClick={() => setOpenDrawer(false)}>
            <Assurances className="icon" />
            <span className="link-name">Assurances</span>
          </NavLink>
        </div>
        <div className="link-container">
          <NavLink to="/acount/facturation" className="link" onClick={() => setOpenDrawer(false)}>
            <Facturation className="icon" />
            <span className="link-name">Facturation</span>
          </NavLink>
        </div>
        <div className="link-container">
          <NavLink to="/acount/setting" className="link" onClick={() => setOpenDrawer(false)}>
            <Profile className="icon" />
            <span className="link-name">Profil</span>
          </NavLink>
        </div>
        <div className="link-container">
          <NavLink to="/acount/contact" className="link" onClick={() => setOpenDrawer(false)}>
            <Contact className="icon" />
            <span className="link-name">Contact</span>
          </NavLink>
        </div>
        <div className="log-out link-container">
          <button type="button" className="link" onClick={() => { logout(); setOpenDrawer(false); }}>
            <ContactMail className="icon" />
            <span className="link-name">Se déconnecter</span>
          </button>
        </div>
      </div>

    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  SetcurrentUser: (user) => dispatch(setCurrentUser(user)),
});

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sidebar);
