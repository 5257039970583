import React, { useState } from 'react';
import Button from '../../../components/buttons/Button/ButtonComponent';
import NavBar from '../../../components/NavBar/NavBarComponent';
import Footer from '../../../components/Footer/FooterComponent';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from '../../../components/finalStep/finalStepConponent';
import './motoStyles.scss';
import { nextStep, previousStep } from '../../../assets/constants/helpers/constants';
import Loadbar from '../../../components/stepper/stepperComponent';

export default function MotoForm() {
  const [stepCounter, setStepCounter] = useState(0);

  const getStep = (Counter) => {
    switch (Counter) {
    case 0:
      return (
        <Step1
          nextStep={nextStep}
          previousStep={previousStep}
        />
      );

    case 1:
      return (
        <Step2
          nextStep={nextStep}
          previousStep={previousStep}
        />
      );

    case 2:
      return (
        <Step3
          nextStep={nextStep}
          previousStep={previousStep}
        />
      );

    case 3:
      return (
        <Step4
          nextStep={nextStep}
          previousStep={previousStep}
        />
      );

    default:
      return '';
    }
  };
  const steps = ['Moto', 'formule', 'Information', 'Confirmation'];

  return (
    <div>
      <NavBar />
      <div className="insurance-form-moto">
        <Loadbar steps={steps} stepr={stepCounter + 1} finalStep={4} />
        {getStep(stepCounter)}
        <div className="btns">
          <div className="btn-back">
            {stepCounter === 0 ? null : (<Button handelClick={() => previousStep(stepCounter, setStepCounter)} reverse> BACK </Button>)}
          </div>
          <div className="btn-next">
            <Button handelClick={() => nextStep(stepCounter, setStepCounter, 3)}> NEXT  </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
