import React from 'react';
import './NavBarStyles.scss';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactComponent as Logo } from '../../assets/icons/logo-silyassur.svg';
import { ReactComponent as Language } from '../../assets/icons/language.svg';
import { ReactComponent as Casc } from '../../assets/icons/casc.svg';
import { ReactComponent as Email } from '../../assets/icons/email.svg';
import { ReactComponent as Search } from '../../assets/icons/search.svg';
import setCurrentUser from '../../../db/redux/user/user-actions';
import getEnvironement from '../../../../environement';

function NavBarComponent({ transparent, currentUser, SetcurrentUser, activeInd, activeEnt }) {
  // const [open, setopen] = useState(false);
  const urlImagePrefix = `${getEnvironement().BACKEND_URL}/uploads/images/users/`;
  const Navigate = useNavigate();
  const logout = () => {
    SetcurrentUser(null);
    Navigate('/auth/login');
  };

  return (
    <div className={`nav-bar ${transparent ? 'transparent' : ''} `}>
      <div className="sub-nav">
        <div className="info">
          <div className="item">
            <div className="icon">
              <Language className="small-icon" />
            </div>
            <div className="text">Francais </div>
          </div>
          <div className="item">
            <div className="icon">
              <Casc className="small-icon" />
            </div>
            <div className="text">+212651888943</div>
          </div>
          <div className="item">
            <div className="icon">
              <Email className="small-icon" />
            </div>
            <div className="text">Contact@insurance.ma</div>
          </div>
        </div>
        <div className="action">
          <div className="second-link"><p>Pourquoi Silyassur ?</p></div>
          {
            currentUser ? (
              <div className="containet-info">
                <div className="image-name">
                  <Link to="/acount/dashboard">
                    <img
                      src={urlImagePrefix + currentUser.profile_image_filename}
                      alt=""
                    />
                  </Link>
                  <span>
                    {currentUser.first_name}
                  </span>
                </div>
                <div className="dropdown">
                  <button type="button" className="btn" onClick={() => Navigate('/acount/dashboard')}> Profil </button>
                  <button type="button" className="btn" onClick={logout}> Se déconnecter </button>
                </div>
              </div>

            ) : (
              <Link to="/auth/login" className="primery-link">Se connecter</Link>
            )
          }

        </div>
      </div>
      <div className="primery-nav">
        <div className="left">
          <div className="logo">
            <Link to="/">
              <Logo className="silyassuer" />
            </Link>
          </div>
          <div className="mode">
            <NavLink to="/entreprise" className={`option1 ${activeEnt ? 'activeEnt' : ''}`}><div> Entreprise</div></NavLink>
            <NavLink to="/" className={`option2 ${activeInd ? 'activeInd' : ''}`}><div> Individuel</div></NavLink>
          </div>
        </div>
        <div className="right">
          <NavLink to="/How-it-works" className="link-1">Comment ça marche</NavLink>
          <NavLink to="/propos" className="link-1"><div>A propos </div></NavLink>
          <NavLink to="/contact-us" className="link-1">Contactez-nous </NavLink>
          <div className="search">
            <Search className="small-icon" />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  SetcurrentUser: (user) => dispatch(setCurrentUser(user)),
});

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBarComponent);
