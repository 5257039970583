import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './overviewStyles.scss';
import { connect } from 'react-redux';
import getEnvironement from '../../../../../../environement';
import BlockData from './components/blockData';
import Info from './components/info';
import Chart from './components/chart';

function Overview({ currentUser }) {
  const urlImagePrefix = `${getEnvironement().BACKEND_URL}/uploads/images/users/`;
  const [data, setData] = useState([]);
  const [nbreAssurances, setNbreAssurances] = useState(0);
  const [ventes, setVentes] = useState(0);
  const [revenus, setRevenus] = useState(0);
  const [expired, setExpired] = useState(0);
  const [Paye, setPaye] = useState(0);

  useEffect(() => {
    const url = `${getEnvironement().API_URL}/clt/dashboard`;
    const body = {
      user_id: currentUser.id,
    };
    const config = {
      headers: {
        Accept: 'application/json',
      },
    };
    axios.post(url, body, config)
      .then((response) => {
        setData(response.data.data.data_annual);
        setNbreAssurances(response.data.data.policies);
        setVentes(response.data.data.sales);
        setRevenus(response.data.data.incomes);
        setExpired(response.data.data.expiring);
        setPaye(response.data.data.paid);
      });
  });
  return (
    <div className="overview-page">
      <div className="containet-info">
        <div className="image-name">
          <img
            src={urlImagePrefix + currentUser.profile_image_filename}
            alt=""
          />
        </div>
        <div className="info">
          <div className="name">
            {`Bonjour ${currentUser.first_name} ${currentUser.last_name}`}
          </div>
        </div>
      </div>
      <div className="blocks-data">
        <BlockData color="#9013FE" content={nbreAssurances} title="assurances" />
        <BlockData color="#1CB222" content={`${ventes} MAD`} title="ventes" />
        <BlockData color="#ff1a1a" content={`${revenus} MAD`} title="revenus" />
        <BlockData color="#FFBB00" content={expired} title="assurances expirées" />
      </div>
      <div className="blocks-info">
        <Info title="Ce mois-ci" width="30">
          <div className="info-content1">Payé</div>
          <div className="info-content2">{`${Paye} DH`}</div>
        </Info>
        <Info title="Revenus 2023" width="67">
          <Chart data={data} />
        </Info>
      </div>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(
  mapStateToProps,
  null,
)(Overview);
