import React, { useRef } from 'react';
import './ProposStyles.scss';
import './Scss/Header.scss';
import './Scss/Partenaires.scss';
import './Scss/Online.scss';
import './Scss/Team.scss';
import './Scss/OurInsurances.scss';
import './Scss/WrapperFooter.scss';
import NavBarComponent from '../../components/NavBar/NavBarComponent';
import Mai from '../../assets/images/home-page/mai.png';
import Rma from '../../assets/images/home-page/RMA.jpg';
import OnlineImg from '../../assets/images/onlineImg.png';
import { ReactComponent as Family } from '../../assets/images/family.svg';
import { ReactComponent as Allianz } from '../../assets/images/home-page/allianz.svg';
import { ReactComponent as Axa } from '../../assets/images/home-page/axa.svg';
import { ReactComponent as Saham } from '../../assets/images/home-page/sahame.svg';
import { ReactComponent as Shape } from '../../assets/icons/shape.svg';
import { ReactComponent as Auto } from '../../assets/icons/auto-2.svg';
import { ReactComponent as Moto } from '../../assets/icons/moto-2.svg';
import { ReactComponent as House } from '../../assets/icons/house-2.svg';
import { ReactComponent as Avion } from '../../assets/icons/avion-2.svg';
import { ReactComponent as Core } from '../../assets/icons/core-2.svg';
import { ReactComponent as Eparnge } from '../../assets/icons/eparnge-2.svg';
import { ReactComponent as People } from '../../assets/icons/people.svg';
import { ReactComponent as Like } from '../../assets/icons/like.svg';
import { ReactComponent as Pen } from '../../assets/icons/pen.svg';
import { ReactComponent as Star } from '../../assets/icons/star.svg';
import { ReactComponent as Entreprise } from '../../assets/icons/entreprise.svg';
import { ReactComponent as Setting } from '../../assets/icons/setting.svg';
import { ReactComponent as Vector } from '../../assets/icons/vector.svg';
import { ReactComponent as Vector2 } from '../../assets/icons/vector2.svg';
import Women from '../../assets/icons/women.svg';
import Footer from '../../components/Footer/FooterComponent';

export default function ProposView() {
  const startPageRef = useRef(null);
  const endOfPageRef = useRef(null);
  const vectorDownClick = () => {
    if (endOfPageRef.current) {
      endOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const vectorUpClick = () => {
    if (startPageRef.current) {
      startPageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className="propos-page" ref={startPageRef}>
      <NavBarComponent />
      <div className="header">
        <div className="text">
          <div className="title">Assurance professionnelle et entreprise</div>
          <div className="sub-title">
            Quel que soit votre secteur d&apos;activité, nous avons l&apos;assurance professionnelle qu&apos;il vous faut !
          </div>
        </div>
        <div className="image">
          <div className="wrapper">
            <div className="rectangle" />
            <div className="ellipse" />
            <Family className="img" />
          </div>
        </div>
      </div>
      <div className="partenaires">
        <Shape className="shape" />
        <div className="title">Nos partenaires pour votre protection</div>
        <div className="text">Nous vous proposons les contrats les plus adaptés parmi nos nombreux partenaires</div>
        <div className="images">
          <Allianz className="image" />
          <Axa className="image" />
          <img src={Mai} alt="" className="image" />
          <img src={Rma} alt="" className="image" />
          <Saham className="image" />
        </div>
      </div>
      <div className="online">
        <Shape className="shape" />
        <div className="wrapper bor">
          <div className="title-1">
            <img src={OnlineImg} alt="" />
            <div className="rectangle2" />
          </div>
          <div className="icons">
            <div className="sub-title"> Nos Assurances</div>

            <div className="wrapper-icon">
              <div className="icon">
                <Auto />
              </div>
              <div className="name">Auto</div>
            </div>
            <div className="wrapper-icon">
              <div className="icon">
                <Moto />
              </div>
              <div className="name">Moto</div>
            </div>
            <div className="wrapper-icon">
              <div className="icon">
                <House />
              </div>
              <div className="name">Habitation</div>
            </div>
            <div className="wrapper-icon">
              <div className="icon">
                <Avion />
              </div>
              <div className="name">Voyage</div>
            </div>
            <div className="wrapper-icon">
              <div className="icon">
                <Eparnge />
              </div>
              <div className="name">Epargne</div>
            </div>
            <div className="wrapper-icon">
              <div className="icon">
                <Core />
              </div>
              <div className="name">Santé</div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="title">
            100% en ligne, 0 papier
          </div>
          <div className="text">
            Finis les délais interminables pour obtenir un devis,
            les corvées d’impression et d’attente à la Poste.
            Souscrivez
            en ligne et obtenez votre attestation instantanément.
          </div>
        </div>
      </div>
      <div className="team">
        <Shape className="shape" />
        <div className="wrapper">
          <div className="title">Une équipe experte dédiée</div>
          <div className="text">
            Ne soyez plus jamais seuls face à vos questions !
            Nos experts répondront à toutes vos interrogations
            et vous accompagneront dans toutes les situations,
            y compris lors de la survenue d’un sinistre.
          </div>
        </div>
        <div className="wrapper">
          <People />
        </div>
      </div>
      <div className="aventage">
        <div className="head"> Oui, on assure</div>
        <div className="container">
          <div className="wrapper">
            <div className="icon"><Like /></div>
            <div className="title">Simole</div>
            <div className="text">
              Souscrivez et gérez tous
              vos contrats d’assurance
              en ligne, simplement.
            </div>
          </div>
          <div className="wrapper center">
            <div className="icon"><Pen /></div>
            <div className="title">Personnalisé</div>
            <div className="text">
              Nous sélectionnons
              des contrats véritablement
              adaptés à votre activité.
            </div>
          </div>
          <div className="wrapper">
            <div className="icon"><Star /></div>
            <div className="title">Compétitif</div>
            <div className="text">
              Nos équipes sont disponibles
              et réactives pour répondre
              à vos questions.
            </div>
          </div>
        </div>
      </div>
      <section className="our-insurances">
        <div className="title">
          <span className="center">

            <span className="transparent">
              Vous,
              <br />
              Votre entreprise

            </span>
            <br />
            Protégez votre activité et celle de vos salariés
          </span>
          <Shape className="shape" />
        </div>
        <div className="insurances">
          <div className="insurance">
            <div className="logo">
              <Entreprise className="icon" />
            </div>
            <div className="name"> Assurances Entreprises</div>
            <div className="description">
              Ce sont les assurances qui protègent vos activités et vos employés.
              Elles permettent de garantir les risques liés à l’exercice de votre activité.
            </div>
            <span className="more">Pour savoir plus... </span>
          </div>
          <div className="insurance">
            <div className="logo indiv">
              <Setting className="icon" />
            </div>
            <div className="name">Assurances Individuel</div>
            <div className="description">
              Ce sont les assurances qui protègent
              les indépendants, qui ne bénéficient pas des mêmes conditions
              de protection que les salariés.
            </div>
            <span className="more">Pour savoir plus... </span>
          </div>
        </div>
      </section>
      <section className="footer">
        <div className="avis-conatine">
          <div
            onClick={vectorUpClick}
            onKeyDown={vectorUpClick}
            role="button"
            tabIndex={0}
            className="vectorUp vector"
          >
            <Vector2 />
          </div>
          <div
            onClick={vectorDownClick}
            onKeyDown={vectorDownClick}
            role="button"
            tabIndex={0}
            className="vectorDown vector"
          >
            <Vector />
          </div>
          <div className="messages">
            <img src={Women} alt="" className="member" />
            <div className="msg-top msg">
              <div className="txt">
                Je voulais protéger ma société mais je ne
                comprenais rien aux assurances. Silyassur m&apos;a accompagnée
                de façon super efficace pour trouver
                les produits qui correspondaient
                le mieux à mon activité.
              </div>
              <div className="auther">
                <span className="name">Samantha Waman</span>
                Lorem ipsum
              </div>
            </div>
            <div className="msg-center msg">
              <div className="txt">
                Je voulais protéger ma société mais je ne
                comprenais rien aux assurances. Silyassur m&apos;a accompagnée
                de façon super efficace pour trouver
                les produits qui correspondaient
                le mieux à mon activité.
              </div>
              <div className="auther">
                <span className="name">Samantha Waman</span>
                <br />
                Lorem ipsum
              </div>
            </div>
            <div className="msg-bottom msg">
              <div className="txt">
                Je voulais protéger ma société mais je ne
                comprenais rien aux assurances. Silyassur m&apos;a accompagnée
                de façon super efficace pour trouver
                les produits qui correspondaient
                le mieux à mon activité.
              </div>
              <div className="auther">
                <span className="name">Samantha Waman</span>
                <br />
                Lorem ipsum
              </div>
            </div>
          </div>
          <div className="wrapper-text">
            <div className="title">
              L&apos;avis de nos clients
            </div>
            <div className="text">
              Nous plaçons la satisfaction client
              au centre de notre métier.
            </div>
          </div>
        </div>
        <div className="contact">
          <div className="wrapper">
            <div className="title">
              Parlez à un expert
              <br />
              <span className="sub-title">… pas à un chatbot</span>
            </div>
            <div className="text">
              Les nouvelles technologies nous aident à vous simplifier la vie,
              mais rassurez-vous, nous ne sommes pas des robots !
              <br />
              <span className="sub-text">Faites-vous rappeler par un expert.</span>
            </div>
            <div className="btn">Porgrammer un appel  </div>
          </div>
        </div>
        <Footer reff={endOfPageRef} />
      </section>
    </div>
  );
}
