import React from 'react';
import Input from '../../../../components/inputs/Input/InputComponent';
import './StepStyles.scss';

export default function step1() {
  return (
    <div className="step-form">
      <div className="group grid">
        <label htmlFor="puissance_fiscale">
          Votre habitation principale est
          <select name="souscription[type]" id="type">
            <option value="1">ville avec jardin</option>
            <option value="1">Maison sans jardin</option>
            <option value="2">Appartement  </option>
          </select>
        </label>
      </div>
      <div className="group">
        <Input type="text" id="date" label="Superficie de votre habitation(m²)" fullwidth />
        <Input type="text" id="date" label="Valeur du bâtiment(Dh)" fullwidth />
      </div>
      <div className="group">
        <Input type="text" id="date" label="Valeur du contenu(Dh)" fullwidth />
        <Input type="text" id="date" label="Objets de valeur(Dh)" fullwidth />
      </div>
    </div>
  );
}
