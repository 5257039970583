import React from 'react';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar, ResponsiveContainer,
} from 'recharts';

function Chart({ data }) {
  return (
    <ResponsiveContainer width="90%" height="92%">
      <BarChart data={data}>
        <Bar dataKey="y" fill="#4D7CFE" />
        <CartesianGrid stroke="#ccc" vertical={false} />
        <XAxis dataKey="x" />
        <YAxis />
        <Tooltip />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Chart;
