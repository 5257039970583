import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './resetPasswordStyles.scss';
import { connect } from 'react-redux';
import Button from '../../components/buttons/Button/ButtonComponent';
import Input from '../../components/inputs/LoginInput/LoginInputComponent';
import NavBar from '../../components/NavBar/NavBarComponent';
import LogoTransp from '../../assets/images/logo-s-transparent.png';
import setCurrentUser from '../../../db/redux/user/user-actions';
import getEnvironement from '../../../../environement';

function ResetPasswordView({ entreprise }) {
  const Navigate = useNavigate();
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handelEmail = (e) => {
    setEmail(e.target.value);
  };

  const handelPassword = (e) => {
    setPassword(e.target.value);
  };

  const handelConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handelSubmit = async (e) => {
    setError('');

    e.preventDefault();
    const body = {
      email,
      password,
      confirmPassword,
    };
    const url = `${getEnvironement().API_URL}/clt/resetpassword`;
    fetch(url, {
      method: 'post',

      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify(
        body,
      ),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'error') {
          setError(response.message);
        } else if (entreprise) {
          Navigate('/auth/loginentreprise?successpass=5xtZc9FDx2P');
        } else {
          Navigate('/auth/login?successpass=5xtZc9FDx2P');
        }
        return response;
      });
  };

  return (
    <div className={`resetPass-page ${entreprise ? 'signin-page-entreprise' : 'signin-page-individuel'}`}>
      <div className={`${entreprise ? 'bg-entreprise' : ''}`} />
      <NavBar transparent />
      <img src={LogoTransp} alt="" className="bg" />
      <div className="container-login">
        <div className="text">
          <span className="simple-text">SILYASSUR</span>
          <h3>
            <span className="simple-text bolder">
              <p>Bienvenue sur</p>
              <p>votre espace client</p>
              <p>Silyassur</p>
            </span>
          </h3>
          <span className="simple-text">Site également consultable sur mobile !</span>
        </div>
        <div className="wrapper-form">
          <p>réinitialisation du mot de passe</p>
          <form onSubmit={handelSubmit}>
            <div className="form-container-sing-in">
              {error.email && <p className="error">{error.email}</p>}
              <Input
                type="text"
                placeholder="Email"
                fullwidth
                useIcon
                onChange={handelEmail}
              />
              {error.password && <p className="error">{error.password}</p>}
              <Input
                type="password"
                id="password"
                placeholder="Mot de Passe"
                fullwidth
                useIcon
                onChange={handelPassword}
              />
              {error.confirmPassword && <p className="error">{error.confirmPassword}</p>}
              <Input
                type="password"
                id="confirmpassword"
                placeholder="Confirmer Mot de Passe"
                fullwidth
                useIcon
                onChange={handelConfirmPassword}
              />
              <Button style={{ position: 'relative', top: '15px' }} fullwidth>Réinitialisation du mot de passe</Button>
            </div>
          </form>
          <p className="link">
            Revenir à la page de connexion ?&nbsp;&nbsp;
            <Link className="cliquez-ici" to={entreprise ? '/auth/loginentreprise' : '/auth/login'}>Cliquez ici</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setcurrentUser: (user) => dispatch(setCurrentUser(user)),
});

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordView);
