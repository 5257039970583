import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  DialogContentText, FormControl, FormHelperText, Grid, InputAdornment, MenuItem, OutlinedInput, Select,
} from '@mui/material';
import { useState } from 'react';

export default function AddNewPaymentDialog({
  open, handleClose, handleClick, listAssurances, userId, error,
}) {
  const [assurance, setAssurance] = useState('');
  const [methode, setMethode] = useState('');
  const [montant, setMontant] = useState('');
  const [date, setDate] = useState('');

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Ajouter un paiement
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {/* assurance */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormHelperText id="assurance">Assurance</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={assurance}
                  onChange={(e) => { setAssurance(e.target.value); }}
                >
                  {listAssurances.map((policy) => <MenuItem key={policy.ref_no} value={policy.ref_no}>{policy.ref_no}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            {/* montant */}
            <Grid item xs={6}>
              <FormControl variant="outlined">
                <FormHelperText id="montant">Montant</FormHelperText>
                <OutlinedInput
                  id="outlined-adornment-montant"
                  type="number"
                  value={montant}
                  onChange={(e) => setMontant(e.target.value)}
                  endAdornment={<InputAdornment position="end">MAD</InputAdornment>}
                  aria-describedby="montant"
                  inputProps={{ min: 0 }}
                />
              </FormControl>
            </Grid>
            {/* date */}
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <FormHelperText id="date-label">Date</FormHelperText>
                <OutlinedInput
                  id="date"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  aria-describedby="date-label"
                />
              </FormControl>
            </Grid>
            {/* methode */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormHelperText id="assurance">Méthode de paiement</FormHelperText>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={methode}
                  onChange={(e) => { setMethode(e.target.value); }}
                >
                  <MenuItem value="card">carte</MenuItem>
                  <MenuItem value="cash">espèces</MenuItem>
                  <MenuItem value="paypal">paypal</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <DialogContentText color="red">
            {error !== null && error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Annuler </Button>
          <Button onClick={() => handleClick(assurance, montant, date, methode, userId)} autoFocus>
            Continuer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
