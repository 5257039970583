import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './LandingStyles.scss';
import './Scss/Header.scss';
import './Scss/Description.scss';
import NavBar from '../../components/NavBar/NavBarComponent';
import { ReactComponent as FiveStart } from '../../assets/icons/fiveStar.svg';
import { ReactComponent as HeaderIndiv } from '../../assets/icons/headerIndiv.svg';
import LoveBrown from '../../assets/icons/love-brown.png';
import { ReactComponent as Auto } from '../../assets/icons/auto.svg';
import { ReactComponent as Moto } from '../../assets/icons/moto.svg';
import { ReactComponent as Route } from '../../assets/icons/route.svg';
import { ReactComponent as House } from '../../assets/icons/house.svg';
import { ReactComponent as Avion } from '../../assets/icons/avion.svg';
import { ReactComponent as Core } from '../../assets/icons/core.svg';
import { ReactComponent as Eparnge } from '../../assets/icons/eparnge.svg';
import { ReactComponent as Loisir } from '../../assets/icons/loisir.svg';
import { ReactComponent as Shape } from '../../assets/icons/shape.svg';
import { ReactComponent as Entreprise } from '../../assets/icons/entreprise.svg';
import { ReactComponent as Setting } from '../../assets/icons/setting.svg';
import Footer from '../../components/FooterFull/FooterFullComponent';

export default function LandingView() {
  const [fixed, setFixed] = useState(false);
  const handleResize = () => {
    if (window.scrollY > 480) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  useEffect(() => {
    window.onscroll = () => {
      handleResize();
    };
  }, []);
  return (
    <div className="home-page">

      {/* header */}
      <NavBar />
      {/* end of header */}
      {/* Header */}
      <div className="header">
        <div className="text">
          <div className="title"><p className="t">Assurances Individuel</p></div>
          <div className="sub-title">
            <p className="t">Souscrire et gérer vos contrats n&apos;a jamais été aussi simple. Silyassur se charge de tout.</p>
          </div>
          <div className="secend-text"><p className="t">Découvrez toutes nos assurances</p></div>
        </div>
        <div className="image">
          <HeaderIndiv className="img" />
        </div>
        <div className="message-love">
          <div className="avi">
            <span className="change-color">Suivre</span>
            <span className="change-color">et</span>
            <span className="change-color">effectuer</span>
            <span className="change-color">les</span>
            <span className="change-color">versements</span>
            <span className="change-color">individuels</span>
          </div>
          <img src={LoveBrown} className="img" alt="" />
        </div>
        <div className="message-rating">
          <div className="top">
            <FiveStart />
            <span className="sub-text"> Nombre total d&apos;avis : 74693</span>
          </div>
          <div className="avi">
            <span className="change-color">Pas</span>
            <span className="change-color">de</span>
            <span className="change-color">commentaires</span>
            <span className="change-color">particuliers</span>
          </div>
        </div>
      </div>
      <div className={`insurances-links ${fixed ? 'fixed' : ''} `} onScroll={handleResize}>
        <Link to="/auto" className="link">
          <Auto className="icon" />
          <span className="name">Auto </span>
        </Link>
        <Link to="/moto" className="link">
          <Moto className="icon" />
          <span className="name">Moto </span>
        </Link>
        <Link to="/accident" className="link">
          <Route className="icon" />
          <span className="name">Accident </span>
        </Link>
        <Link to="/habitation" className="link">
          <House className="icon" />
          <span className="name">HAbitation </span>
        </Link>
        <Link to="/voyage" className="link">
          <Avion className="icon" />
          <span className="name">Voyage </span>
        </Link>
        <Link to="/sante" className="link">
          <Core className="icon" />
          <span className="name">Santé Prévoyance </span>
        </Link>
        <Link to="/epargne" className="link">
          <Eparnge className="icon" />
          <span className="name">Epargne </span>
        </Link>
        <Link to="/loisir" className="link">
          <Loisir className="icon" />
          <span className="name">Loisir </span>
        </Link>
      </div>
      <div className="description">
        <div className="title">
          <span className="center">De quoi s&apos;agit-il ?</span>
          <Shape className="shape" />
        </div>
        <div className="detailes">
          <div className="wraper">
            <span className="text">
              Ce sont les assurances qui protègent les travailleurs
              indépendants, qui ne bénéficient pas des mêmes conditions
              de protection que les salariés. Elles peuvent indemniser
              , par exemple, l&apos;indépendant à la suite d&apos;une incapacité temporaire
              ou définitive de travail.
            </span>
          </div>
        </div>
      </div>
      <section className="our-insurances">
        <div className="title">
          <span className="center">
            <p>Nos assurances</p>
            <p>Entreprises & Individuel</p>
          </span>
          <Shape className="shape" />
        </div>
        <div className="insurances">
          <div className="insurance">
            <div className="logo">
              <Entreprise className="icon" />
            </div>
            <div className="name"> Assurances Entreprises</div>
            <div className="description">
              <p>
                Ce sont les assurances qui protègent vos activités et vos employés.
                Elles permettent de garantir les risques liés à l’exercice de votre activité.
              </p>
            </div>
            <span className="more">Pour savoir plus... </span>
          </div>
          <div className="insurance">
            <div className="logo indiv">
              <Setting className="icon" />
            </div>
            <div className="name">Assurances Individuel</div>
            <div className="description">
              <p>
                Ce sont les assurances qui protègent
                les indépendants, qui ne bénéficient pas des mêmes conditions
                de protection que les salariés.
              </p>
            </div>
            <span className="more">Pour savoir plus... </span>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
