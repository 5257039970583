import React from 'react';
import Close from '@mui/icons-material/Close';
import Done from '@mui/icons-material/Done';
import Info from '@mui/icons-material/Info';
import { nextStep } from '../../../../assets/constants/helpers/constants';

export default function Step2({ setOffre, stepCounter, setStepCounter, setPrixOffre, onClickCommander }) {
  return (
    <div className="step-form">
      <div className="options-ac">
        <div className="option-ac">
          <div className="box-v2">
            <div className="title"><h2>Start</h2></div>
          </div>
          <div className="box-v2">
            <div className="ul">
              <div className="services-pr">
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Responsabilité civile</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Défense et Recours </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Personnes transportées</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Protection conducteur</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Assistance</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Close className="close" /></div>
                  <div className="text">Incendie</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Close className="close" /></div>
                  <div className="text">Vol </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Close className="close" /></div>
                  <div className="text">Bris de glace </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Close className="close" /></div>
                  <div className="text">Inondation </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Close className="close" /></div>
                  <div className="text">Dommages collision</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Close className="close" /></div>
                  <div className="text">Tierce </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-v2">
            <div className="price">
              4241.07 DHS/AN TTC
            </div>
          </div>
          <button
            type="button"
            className="commander"
            onClick={() => {
              setOffre('Start');
              setPrixOffre(4241.07);
              nextStep(stepCounter, setStepCounter, 3);
              onClickCommander();
            }}
          >
            COMMANDER
          </button>
        </div>
        <div className="option-ac orange">
          <div className="box-v2">
            <div className="title"><h2>Go</h2></div>
          </div>
          <div className="box-v2">
            <div className="ul">
              <div className="services-pr">
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Responsabilité civile</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Défense et Recours </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Personnes transportées</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Protection conducteur</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Assistance</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Incendie</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Vol </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Bris de glace </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Inondation </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Dommages collision</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Close className="close" /></div>
                  <div className="text">Tierce </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-v2">
            <div className="price">
              5268.03 DHS/AN TTC
            </div>
          </div>
          <button
            className="commander orange-b"
            type="button"
            onClick={() => {
              setOffre('Go');
              setPrixOffre(5268.03);
              nextStep(stepCounter, setStepCounter, 3);
              onClickCommander();
            }}
          >
            COMMANDER
          </button>
        </div>
        <div className="option-ac kiwi">
          <div className="box-v2">
            <div className="title"><h2>Fast</h2></div>
          </div>
          <div className="box-v2">
            <div className="ul">
              <div className="services-pr">
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Responsabilité civile</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Défense et Recours </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Personnes transportées</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Protection conducteur</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Assistance</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Incendie</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Vol </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Bris de glace </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Inondation </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Dommages collision</div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
                <div className="service">
                  <div className="icon"><Done className="done" /></div>
                  <div className="text">Tierce </div>
                  <div className="info-icon"><Info className="info" /></div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-v2">
            <div className="price">
              6061.15 DHS/AN TTC
            </div>
          </div>
          <button
            className="commander kiwi-b"
            type="button"
            onClick={() => {
              setOffre('Fast');
              setPrixOffre(6061.15);
              nextStep(stepCounter, setStepCounter, 3);
              onClickCommander();
            }}
          >
            COMMANDER
          </button>
        </div>
      </div>

    </div>
  );
}
