import React from 'react';
import './App.css';
import RouteNavigator from './main/graphics/components/navigators/RouteNavigator';
import LoginView from './main/graphics/views/login/LoginView';
import RegistrationView from './main/graphics/views/register/RegistrationView';
import LandingView from './main/graphics/views/landingPage/LandingView';
import ProposView from './main/graphics/views/proposPage/ProposView';
import EntrepriseView from './main/graphics/views/landingPageEntreprise/EntrepriseView';
import CheckLogin from './main/graphics/components/checkLogin/checkLoginComponent';
import CheckLoginEntreprise from './main/graphics/components/checkLoginEntreprise/checkLoginEntrepriseComponent';
import LoginUtil from './main/graphics/helpers/check-login-utils';
import AutoForm from './main/graphics/views/individualInsura/auto/autoView';
import MotoForm from './main/graphics/views/individualInsura/moto/motoView';
import VoyageForm from './main/graphics/views/individualInsura/voyage/voyageView';
import LoisirForm from './main/graphics/views/individualInsura/loisir/loisirView';
import SanteForm from './main/graphics/views/individualInsura/sante/santeView';
import EpargneForm from './main/graphics/views/individualInsura/epargne/epargneView';
import HabitationForm from './main/graphics/views/individualInsura/habitation/habitationView';
import AccidentForm from './main/graphics/views/individualInsura/accident/accidentView';
import ProfileView from './main/graphics/views/Profile/ProfileView';
import Assurances from './main/graphics/views/Profile/sections/assurances/assurancesComponent';
import Profile from './main/graphics/views/Profile/sections/profile/profile';
import Setting from './main/graphics/views/Profile/sections/setting/settingComponent';
import facturation from './main/graphics/views/Profile/sections/facturation/facturation';
import Contact from './main/graphics/views/Profile/sections/contact/contact';
import Overview from './main/graphics/views/Profile/sections/overview/overview';
import ForgotpasswordView from './main/graphics/views/forgotpassword/forgotpasswordView';
import ResetPasswordView from './main/graphics/views/resetpassword/resetPasswordView';

export default function App() {
  return (
    <div className="App">
      <RouteNavigator config={layoutsConfig} DefaultView={LandingView} />
    </div>
  );
}
const LAYOUTS = {
  AUTHENTICATION: 'auth',
  REGISTRATION: 'registration',
  FORGOTPASSWORD: 'forgotpassword',
  RESETPASSWORD: 'resetpassword',
  REGISTRATION_ENTREPRISE: 'registrationentreprise',
  FORGOTPASSWORD_ENTREPRISE: 'forgotpasswordentreprise',
  RESETPASSWORD_ENTREPRISE: 'resetpasswordentreprise',
  LandingView: 'landingView',
  ENTREPRISEVIEW: 'entreprise',
  AUTO: 'auto',
  MOTO: 'moto',
  VOYAGE: 'voyage',
  LOISIR: 'loisir',
  SANTE: 'sante',
  EPARGNE: 'epargne',
  HABITATION: 'habitation',
  ACCIDENT: 'accident',
  PROPOS: 'propos',
  ACOUNT: 'acount',
  ASSURMULTIPRO: 'assurmultipro',
  GESTIONFLOTTE: 'gestionFlotte',
  GESTIONFLOTTECONNECTE: 'gestionFlotteConnectee',
  TRANSPORTMARCHANDISE: 'transportMarchandise',
  NAVIREPECHE: 'navirePeche',
  ASSURMOUKAWALATI: 'assurMoukawalati',
  AUTOSALARIE: 'autoSalarie',
  MOTOSALARIE: 'motoSalarie',
  ACCIDENTSALARIE: 'accidentSalarie',
  ABITATIONSALARIE: 'abitationSalarie',
  EPARGNESALARIE: 'epargneSalarie',
  LOISIRSALARIE: 'loisirSalarie',
  ACCIDENTCIVILE: 'accidentCivile',
  ABITATIONCIVILE: 'abitationCivile',
  EPARGNECIVILE: 'epargneCivile',
  LOISIRCIVILE: 'loisirCivile',
  SANTECIVILE: 'santeCivile',
  VOYAGECIVILE: 'voyageCivile',
};

const layoutsConfig = {
  [LAYOUTS.AUTHENTICATION]: [
    {
      name: 'Login View',
      path: 'login',
      Component: () => <LoginView />,
    },
    {
      name: 'Login View',
      path: 'loginentreprise',
      Component: () => <LoginView entreprise />,
    },
  ],
  [LAYOUTS.REGISTRATION]: [
    {
      name: 'Registration View',
      path: '',
      Component: () => <RegistrationView />,
    },
  ],
  [LAYOUTS.FORGOTPASSWORD]: [
    {
      name: 'Forgot password View',
      path: '',
      Component: () => <ForgotpasswordView />,
    },
  ],
  [LAYOUTS.RESETPASSWORD]: [
    {
      name: 'Reset password View',
      path: '',
      Component: () => <ResetPasswordView />,
    },
  ],

  [LAYOUTS.REGISTRATION_ENTREPRISE]: [
    {
      name: 'Registration View',
      path: '',
      Component: () => <RegistrationView entreprise />,
    },
  ],
  [LAYOUTS.FORGOTPASSWORD_ENTREPRISE]: [
    {
      name: 'Forgot password View',
      path: '',
      Component: () => <ForgotpasswordView entreprise />,
    },
  ],
  [LAYOUTS.RESETPASSWORD_ENTREPRISE]: [
    {
      name: 'Reset password View',
      path: '',
      Component: () => <ResetPasswordView entreprise />,
    },
  ],
  [LAYOUTS.LandingView]: [
    {
      name: 'Overview View',
      path: '',
      Component: LandingView,
    },
  ],
  [LAYOUTS.ENTREPRISEVIEW]: [
    {
      name: 'entreprise View',
      path: '',
      Component: EntrepriseView,
    },
  ],
  [LAYOUTS.AUTO]: [
    {
      name: 'Auto',
      path: '',
      Component: () => (
        <CheckLogin
          image={LoginUtil.AUTO.image}
          title={LoginUtil.AUTO.title}
          text={LoginUtil.AUTO.text}
          type={LoginUtil.AUTO.type}
          url={LoginUtil.AUTO.url}
          individuel
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.MOTO]: [
    {
      name: 'Moto',
      path: '',
      Component: () => (
        <CheckLogin
          image={LoginUtil.MOTO.image}
          title={LoginUtil.MOTO.title}
          text={LoginUtil.MOTO.text}
          type={LoginUtil.MOTO.type}
          url={LoginUtil.MOTO.url}
          individuel

        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: MotoForm,
    },
  ],
  [LAYOUTS.VOYAGE]: [
    {
      name: 'Voyage',
      path: '',
      Component: () => (
        <CheckLogin
          image={LoginUtil.VOYAGE.image}
          title={LoginUtil.VOYAGE.title}
          text={LoginUtil.VOYAGE.text}
          type={LoginUtil.VOYAGE.type}
          url={LoginUtil.VOYAGE.url}
          individuel
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: VoyageForm,
    },
  ],
  [LAYOUTS.LOISIR]: [
    {
      name: 'Loisir',
      path: '',
      Component: () => (
        <CheckLogin
          image={LoginUtil.LOISIR.image}
          title={LoginUtil.LOISIR.title}
          text={LoginUtil.LOISIR.text}
          type={LoginUtil.LOISIR.type}
          url={LoginUtil.LOISIR.url}
          individuel
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: LoisirForm,
    },
  ],
  [LAYOUTS.SANTE]: [
    {
      name: 'sante',
      path: '',
      Component: () => (
        <CheckLogin
          image={LoginUtil.SANTE.image}
          title={LoginUtil.SANTE.title}
          text={LoginUtil.SANTE.text}
          type={LoginUtil.SANTE.type}
          url={LoginUtil.SANTE.url}
          individuel
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: SanteForm,
    },
  ],
  [LAYOUTS.EPARGNE]: [
    {
      name: 'epargne',
      path: '',
      Component: () => (
        <CheckLogin
          image={LoginUtil.EPARGNE.image}
          title={LoginUtil.EPARGNE.title}
          text={LoginUtil.EPARGNE.text}
          type={LoginUtil.EPARGNE.type}
          url={LoginUtil.EPARGNE.url}
          individuel
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: EpargneForm,
    },
  ],
  [LAYOUTS.HABITATION]: [
    {
      name: 'habitation',
      path: '',
      Component: () => (
        <CheckLogin
          image={LoginUtil.HABITATION.image}
          title={LoginUtil.HABITATION.title}
          text={LoginUtil.HABITATION.text}
          type={LoginUtil.HABITATION.type}
          url={LoginUtil.HABITATION.url}
          individuel
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: HabitationForm,
    },
  ],
  [LAYOUTS.ACCIDENT]: [
    {
      name: 'accident',
      path: '',
      Component: () => (
        <CheckLogin
          image={LoginUtil.ACCIDENT.image}
          title={LoginUtil.ACCIDENT.title}
          text={LoginUtil.ACCIDENT.text}
          type={LoginUtil.ACCIDENT.type}
          url={LoginUtil.ACCIDENT.url}
          individuel
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AccidentForm,
    },
  ],
  [LAYOUTS.ASSURMULTIPRO]: [
    {
      name: 'assurmultipro',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.ASSURMULTIPRO.image}
          title={LoginUtil.ASSURMULTIPRO.title}
          text={LoginUtil.ASSURMULTIPRO.text}
          type={LoginUtil.ASSURMULTIPRO.type}
          url={LoginUtil.ASSURMULTIPRO.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.GESTIONFLOTTE]: [
    {
      name: 'gestionFlotte',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.GESTIONFLOTTE.image}
          title={LoginUtil.GESTIONFLOTTE.title}
          text={LoginUtil.GESTIONFLOTTE.text}
          type={LoginUtil.GESTIONFLOTTE.type}
          url={LoginUtil.GESTIONFLOTTE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.GESTIONFLOTTECONNECTE]: [
    {
      name: 'gestionFlotteConnectee',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.GESTIONFLOTTECONNECTE.image}
          title={LoginUtil.GESTIONFLOTTECONNECTE.title}
          text={LoginUtil.GESTIONFLOTTECONNECTE.text}
          type={LoginUtil.GESTIONFLOTTECONNECTE.type}
          url={LoginUtil.GESTIONFLOTTECONNECTE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.TRANSPORTMARCHANDISE]: [
    {
      name: 'transportMarchandise',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.TRANSPORTMARCHANDISE.image}
          title={LoginUtil.TRANSPORTMARCHANDISE.title}
          text={LoginUtil.TRANSPORTMARCHANDISE.text}
          type={LoginUtil.TRANSPORTMARCHANDISE.type}
          url={LoginUtil.TRANSPORTMARCHANDISE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.NAVIREPECHE]: [
    {
      name: 'navirePeche',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.NAVIREPECHE.image}
          title={LoginUtil.NAVIREPECHE.title}
          text={LoginUtil.NAVIREPECHE.text}
          type={LoginUtil.NAVIREPECHE.type}
          url={LoginUtil.NAVIREPECHE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.ASSURMOUKAWALATI]: [
    {
      name: 'assurMoukawalati',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.ASSURMOUKAWALATI.image}
          title={LoginUtil.ASSURMOUKAWALATI.title}
          text={LoginUtil.ASSURMOUKAWALATI.text}
          type={LoginUtil.ASSURMOUKAWALATI.type}
          url={LoginUtil.ASSURMOUKAWALATI.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.AUTOSALARIE]: [
    {
      name: 'autoSalarie',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.AUTOSALARIE.image}
          title={LoginUtil.AUTOSALARIE.title}
          text={LoginUtil.AUTOSALARIE.text}
          type={LoginUtil.AUTOSALARIE.type}
          url={LoginUtil.AUTOSALARIE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.MOTOSALARIE]: [
    {
      name: 'motoSalarie',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.MOTOSALARIE.image}
          title={LoginUtil.MOTOSALARIE.title}
          text={LoginUtil.MOTOSALARIE.text}
          type={LoginUtil.MOTOSALARIE.type}
          url={LoginUtil.MOTOSALARIE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.ABITATIONSALARIE]: [
    {
      name: 'abitationSalarie',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.ABITATIONSALARIE.image}
          title={LoginUtil.ABITATIONSALARIE.title}
          text={LoginUtil.ABITATIONSALARIE.text}
          type={LoginUtil.ABITATIONSALARIE.type}
          url={LoginUtil.ABITATIONSALARIE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.ACCIDENTSALARIE]: [
    {
      name: 'accidentSalarie',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.ACCIDENTSALARIE.image}
          title={LoginUtil.ACCIDENTSALARIE.title}
          text={LoginUtil.ACCIDENTSALARIE.text}
          type={LoginUtil.ACCIDENTSALARIE.type}
          url={LoginUtil.ACCIDENTSALARIE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.LOISIRSALARIE]: [
    {
      name: 'loisirSalarie',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.LOISIRSALARIE.image}
          title={LoginUtil.LOISIRSALARIE.title}
          text={LoginUtil.LOISIRSALARIE.text}
          type={LoginUtil.LOISIRSALARIE.type}
          url={LoginUtil.LOISIRSALARIE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.EPARGNESALARIE]: [
    {
      name: 'epargneSalarie',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.EPARGNESALARIE.image}
          title={LoginUtil.EPARGNESALARIE.title}
          text={LoginUtil.EPARGNESALARIE.text}
          type={LoginUtil.EPARGNESALARIE.type}
          url={LoginUtil.EPARGNESALARIE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.ABITATIONCIVILE]: [
    {
      name: 'abitationCivile',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.ABITATIONCIVILE.image}
          title={LoginUtil.ABITATIONCIVILE.title}
          text={LoginUtil.ABITATIONCIVILE.text}
          type={LoginUtil.ABITATIONCIVILE.type}
          url={LoginUtil.ABITATIONCIVILE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.ACCIDENTCIVILE]: [
    {
      name: 'accidentCivile',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.ACCIDENTCIVILE.image}
          title={LoginUtil.ACCIDENTCIVILE.title}
          text={LoginUtil.ACCIDENTCIVILE.text}
          type={LoginUtil.ACCIDENTCIVILE.type}
          url={LoginUtil.ACCIDENTCIVILE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.LOISIRCIVILE]: [
    {
      name: 'loisirCivile',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.LOISIRCIVILE.image}
          title={LoginUtil.LOISIRCIVILE.title}
          text={LoginUtil.LOISIRCIVILE.text}
          type={LoginUtil.LOISIRCIVILE.type}
          url={LoginUtil.LOISIRCIVILE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.EPARGNECIVILE]: [
    {
      name: 'epargneCivile',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.EPARGNECIVILE.image}
          title={LoginUtil.EPARGNECIVILE.title}
          text={LoginUtil.EPARGNECIVILE.text}
          type={LoginUtil.EPARGNECIVILE.type}
          url={LoginUtil.EPARGNECIVILE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.VOYAGECIVILE]: [
    {
      name: 'voyageCivile',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.VOYAGECIVILE.image}
          title={LoginUtil.VOYAGECIVILE.title}
          text={LoginUtil.VOYAGECIVILE.text}
          type={LoginUtil.VOYAGECIVILE.type}
          url={LoginUtil.VOYAGECIVILE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.SANTECIVILE]: [
    {
      name: 'santeCivile',
      path: '',
      Component: () => (
        <CheckLoginEntreprise
          image={LoginUtil.SANTECIVILE.image}
          title={LoginUtil.SANTECIVILE.title}
          text={LoginUtil.SANTECIVILE.text}
          type={LoginUtil.SANTECIVILE.type}
          url={LoginUtil.SANTECIVILE.url}
          entreprise
        />
      ),
    },
    {
      name: 'form',
      path: 'form',
      Component: AutoForm,
    },
  ],
  [LAYOUTS.PROPOS]: [
    {
      name: 'propos',
      path: '',
      Component: ProposView,
    },
    // add form moto
  ],
  [LAYOUTS.ACOUNT]: [
    {
      name: 'acount',
      path: '/acount',
      Component: ProfileView,
      nested: [{
        name: 'assurances',
        subPath: 'assurances',
        SubComponent: Assurances,
      }, {
        name: 'profile',
        subPath: 'profile',
        SubComponent: Profile,
      }, {
        name: 'setting',
        subPath: 'setting',
        SubComponent: Setting,
      }, {
        name: 'facturation',
        subPath: 'facturation',
        SubComponent: facturation,
      }, {
        name: 'contact',
        subPath: 'contact',
        SubComponent: Contact,
      }, {
        name: 'dashboard',
        subPath: 'dashboard',
        SubComponent: Overview,
      }],
    },
  ],
};
