import React from 'react';

function BlockData({ color, content, title }) {
  return (
    <div className="blockdata" style={{ backgroundColor: color }}>
      <p className="content">{ content }</p>
      <p className="title">{ title }</p>
    </div>
  );
}

export default BlockData;
