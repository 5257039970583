import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../../components/buttons/Button/ButtonComponent';
import NavBar from '../../../components/NavBar/NavBarComponent';
import Footer from '../../../components/Footer/FooterComponent';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from '../../../components/finalStep/finalStepConponent';
import './autoStyles.scss';
import { nextStep, previousStep } from '../../../assets/constants/helpers/constants';
import Loadbar from '../../../components/stepper/stepperComponent';
import getEnvironement from '../../../../../environement';
import setCurrentUser from '../../../../db/redux/user/user-actions';
import SimpleBackdrop from '../../../components/backDrop/backDrop';
import AlertDialog from './alertDialog/alertDialog';
import AlertDialogOk from './alertDialog/alertDialogOk';

function Insurance({ currentUser, SetcurrentUser }) {
  const [stepCounter, setStepCounter] = useState(0);

  const [Energy, setEnergy] = useState('');
  const [Powerful, setPowerful] = useState('');
  const [NewValue, setNewValue] = useState('');
  const [DateOfCirculation, setDateOfCirculation] = useState('');
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [offre, setOffre] = useState('');
  const [prixOffre, setPrixOffre] = useState('');
  const [userId] = useState(currentUser.id);

  const [permirecto, setPermirecto] = useState(null);
  const [permirectoPrev, setPermirectoPrev] = useState(null);
  const [permiverso, setpermiVerso] = useState(null);
  const [permiversoPrev, setpermiVersoPrev] = useState(null);
  const [cnirecto, setcnirecto] = useState(null);
  const [cnirectoPrev, setcnirectoPrev] = useState(null);
  const [cniverso, setcniverso] = useState(null);
  const [cniversoPrev, setcniversoPrev] = useState(null);

  const [isStep1Valid, setIsStep1Valid] = useState(false);
  const [isStep3Valid, setIsStep3Valid] = useState(false);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [openAlertDialogOk, setOpenAlertDialogOk] = useState(false);
  const [updatePermiRecto, setUpdatePermiRecto] = useState(false);
  const [showOpenPermiRecto, setShowOpenPermiRecto] = useState(false);
  const [openPermiRectoDialog, setOpenPermiRectoDialog] = useState(false);
  const [updatePermiVerso, setUpdatePermiVerso] = useState(false);
  const [showOpenPermiVerso, setShowOpenPermiVerso] = useState(false);
  const [openPermiVersoDialog, setOpenPermiVersoDialog] = useState(false);
  const [updateCniRecto, setUpdateCniRecto] = useState(false);
  const [showOpenCniRecto, setShowOpenCniRecto] = useState(false);
  const [openCniRectoDialog, setOpenCniRectoDialog] = useState(false);
  const [updateCniVerso, setUpdateCniVerso] = useState(false);
  const [showOpenCniVerso, setShowOpenCniVerso] = useState(false);
  const [openCniVersoDialog, setOpenCniVersoDialog] = useState(false);

  const permiRectoInputRef = useRef(null);
  const permiVersoInputRef = useRef(null);
  const cniRectoInputRef = useRef(null);
  const cniVersoInputRef = useRef(null);

  const Navigate = useNavigate();

  function getFiles() {
    const url = `${getEnvironement().API_URL}/clients/getFiles`;
    const body = { id: userId };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axios.post(url, body, config)
      .then((response) => response.data)
      .then((response) => {
        if (response.cinRecto) {
          setcnirectoPrev(`${getEnvironement().BACKEND_URL}/storage/app/attachments/${response.cinRecto}`);
        }
        if (response.cinVerso) {
          setcniversoPrev(`${getEnvironement().BACKEND_URL}/storage/app/attachments/${response.cinVerso}`);
        }
        if (response.permiRecto) {
          setPermirectoPrev(`${getEnvironement().BACKEND_URL}/storage/app/attachments/${response.permiRecto}`);
        }
        if (response.permiVerso) {
          setpermiVersoPrev(`${getEnvironement().BACKEND_URL}/storage/app/attachments/${response.permiVerso}`);
        }
      });
  }

  useEffect(() => {
    if (updatePermiRecto && permirecto !== null) {
      updateFile('permi_recto', permirecto);
    }
    if (updatePermiVerso && permiverso !== null) {
      updateFile('permi_verso', permiverso);
    }
    if (updateCniRecto && cnirecto !== null) {
      updateFile('cni_recto', cnirecto);
    }
    if (updateCniVerso && cniverso !== null) {
      updateFile('cni_verso', cniverso);
    }
    getFiles();
  });

  function isDisabled(stepCount) {
    if (stepCount === 0) {
      return !isStep1Valid;
    }
    if (stepCount === 2) {
      return !isStep3Valid;
    }
    return false;
  }

  function resetForm(step) {
    if (step === 1) {
      setDateOfCirculation('');
      setEnergy('');
      setPowerful('');
      setNewValue('');
    } else if (step === 3) {
      setStartDate('');
      setEndDate('');
      setPermirectoPrev(null);
      setPermirecto(null);
      setpermiVersoPrev(null);
      setpermiVerso(null);
      setcnirectoPrev(null);
      setcnirecto(null);
      setcniversoPrev(null);
      setcniverso(null);
    }
  }
  function updateFile(name, filename) {
    const url = `${getEnvironement().API_URL}/demand/updateFile`;
    const body = {
      id: currentUser.id,
      name,
      filename,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axios.post(url, body, config)
      .then((response) => {
        setUpdatePermiRecto(false);
        setUpdatePermiVerso(false);
        setUpdateCniRecto(false);
        setUpdateCniVerso(false);
        if (response.data.filename !== null) {
          setOpenAlertDialogOk(true);
        }
      });
  }
  const handleClickPermiRectoDialog = () => {
    new Promise((resolve) => {
      setShowOpenPermiRecto(true);
      resolve();
    })
      .then(() => {
        permiRectoInputRef.current.click();
        setShowOpenPermiRecto(false);
        setOpenPermiRectoDialog(false);
      });
  };
  const handleClickPermiVersoDialog = () => {
    new Promise((resolve) => {
      setShowOpenPermiVerso(true);
      resolve();
    })
      .then(() => {
        permiVersoInputRef.current.click();
        setShowOpenPermiVerso(false);
        setOpenPermiVersoDialog(false);
      });
  };
  const handleClickCniRectoDialog = () => {
    new Promise((resolve) => {
      setShowOpenCniRecto(true);
      resolve();
    })
      .then(() => {
        cniRectoInputRef.current.click();
        setShowOpenCniRecto(false);
        setOpenCniRectoDialog(false);
      });
  };
  const handleClickCniVersoDialog = () => {
    new Promise((resolve) => {
      setShowOpenCniVerso(true);
      resolve();
    })
      .then(() => {
        cniVersoInputRef.current.click();
        setShowOpenCniVerso(false);
        setOpenCniVersoDialog(false);
      });
  };
  const handleClosePermiRectoDialog = () => setOpenPermiRectoDialog(false);
  const handleClosePermiVersoDialog = () => setOpenPermiVersoDialog(false);
  const handleCloseCniRectoDialog = () => setOpenCniRectoDialog(false);
  const handleCloseCniVersoDialog = () => setOpenCniVersoDialog(false);
  const handleCloseAlertDialogOk = () => setOpenAlertDialogOk(false);
  const handelEnergy = (e) => {
    setEnergy(e.target.value);
  };
  const handelPowerful = (e) => {
    setPowerful(e.target.value);
  };
  const handelNewValue = (e) => {
    setNewValue(e.target.value);
  };
  const handelDateOfCirculation = (e) => {
    setDateOfCirculation(e.target.value);
  };
  const handelStartDate = (e) => {
    setStartDate(e.target.value);
  };
  const handelEndDate = (e) => {
    setEndDate(e.target.value);
  };
  const handleClickPermiRecto = (e) => {
    if (!showOpenPermiRecto) {
      e.preventDefault();
    }
    setOpenPermiRectoDialog(true);
  };
  const handleClickPermiVerso = (e) => {
    if (!showOpenPermiVerso) {
      e.preventDefault();
    }
    setOpenPermiVersoDialog(true);
  };
  const handleClickCniRecto = (e) => {
    if (!showOpenCniRecto) {
      e.preventDefault();
    }
    setOpenCniRectoDialog(true);
  };
  const handleClickCniVerso = (e) => {
    if (!showOpenCniVerso) {
      e.preventDefault();
    }
    setOpenCniVersoDialog(true);
  };

  const handelPermirecto = (e) => {
    setUpdatePermiRecto(true);
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      setPermirectoPrev(null);
      setPermirecto(null);
      return;
    }
    setPermirectoPrev(URL.createObjectURL(e.target.files[0]));

    const reader = new FileReader();
    reader.onload = (evt) => {
      setPermirecto(evt.target.result);
    };
    reader.readAsDataURL(files[0]);
  };
  const handelpermiVerso = (e) => {
    setUpdatePermiVerso(true);
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      setpermiVersoPrev(null);
      setpermiVerso(null);
      return;
    }
    setpermiVersoPrev(URL.createObjectURL(e.target.files[0]));

    const reader = new FileReader();
    reader.onload = (evt) => {
      setpermiVerso(evt.target.result);
    };
    reader.readAsDataURL(files[0]);
  };
  const handelcniverso = (e) => {
    setUpdateCniVerso(true);
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      setcniversoPrev(null);
      setcniverso(null);
      return;
    }
    setcniversoPrev(URL.createObjectURL(e.target.files[0]));

    const reader = new FileReader();
    reader.onload = (evt) => {
      setcniverso(evt.target.result);
    };
    reader.readAsDataURL(files[0]);
  };
  const handelcnirecto = (e) => {
    setUpdateCniRecto(true);
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      setcnirectoPrev(null);
      setcnirecto(null);
      return;
    }
    setcnirectoPrev(URL.createObjectURL(e.target.files[0]));

    const reader = new FileReader();
    reader.onload = (evt) => {
      setcnirecto(evt.target.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    setOpenBackdrop(true);
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    };

    const body = {
      Energy,
      Powerful,
      NewValue,
      DateOfCirculation,
      StartDate,
      EndDate,
      offre,
      prixOffre,
      categorie: 'individuel',
      type: 'auto',
      user_id: userId,
      permirecto,
      permiverso,
      cnirecto,
      cniverso,
    };
    const url = `${getEnvironement().API_URL}/demand`;
    axios.post(url, body, config)
      .then((response) => response)
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response);
        toast.success('Félicitations, vous allez recevoir par email votre devis!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      })
      .then(() => saveCurrentUser(e))
      .then(() => setOpenBackdrop(false))
      .then(() => Navigate('/acount/assurances'))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        setOpenBackdrop(false);
      });
  };

  const saveCurrentUser = async (e) => {
    e.preventDefault();
    const body = {
      userId: currentUser.id,
    };
    const url = `${getEnvironement().API_URL}/clients/currentUser`;
    fetch(url, {
      method: 'post',

      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify(
        body,
      ),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.message) {
          const { user } = response;
          user.policies = response.policies;
          user.payments = response.payments;
          user.attachments = response.attachments;
          SetcurrentUser(user);
        }
        return response;
      });
  };
  const getStep = (Counter) => {
    switch (Counter) {
    case 0:
      return (
        <Step1
          nextStep={nextStep}
          previousStep={previousStep}
          handelEnergy={handelEnergy}
          sendIsValid={(isValid) => setIsStep1Valid(isValid)}
          Energy={Energy}
          handelPowerful={handelPowerful}
          Powerful={Powerful}
          handelNewValue={handelNewValue}
          NewValue={NewValue}
          handelDateOfCirculation={handelDateOfCirculation}
          DateOfCirculation={DateOfCirculation}
        />
      );

    case 1:
      return (
        <Step2
          nextStep={nextStep}
          previousStep={previousStep}
          setOffre={setOffre}
          setPrixOffre={setPrixOffre}
          stepCounter={stepCounter}
          setStepCounter={setStepCounter}
          onClickCommander={() => resetForm(3)}
        />
      );

    case 2:
      return (
        <Step3
          nextStep={nextStep}
          previousStep={previousStep}
          handelStartDate={handelStartDate}
          StartDate={StartDate}
          handelEndDate={handelEndDate}
          EndDate={EndDate}
          handelPermirecto={handelPermirecto}
          handelpermiVerso={handelpermiVerso}
          handelcnirecto={handelcnirecto}
          handelcniverso={handelcniverso}
          permirecto={permirecto}
          permirectoPrev={permirectoPrev}
          permiversoPrev={permiversoPrev}
          cnirectoPrev={cnirectoPrev}
          cniversoPrev={cniversoPrev}
          sendIsValid={(isValid) => setIsStep3Valid(isValid)}
          userId={currentUser.id}
          permiRectoRef={(input) => { permiRectoInputRef.current = input; }}
          permiVersoRef={(input) => { permiVersoInputRef.current = input; }}
          cniRectoRef={(input) => { cniRectoInputRef.current = input; }}
          cniVersoRef={(input) => { cniVersoInputRef.current = input; }}
          onClickPermiRecto={permirectoPrev !== null ? handleClickPermiRecto : () => {}}
          onClickPermiVerso={permiversoPrev !== null ? handleClickPermiVerso : () => {}}
          onClickCniRecto={cnirectoPrev !== null ? handleClickCniRecto : () => {}}
          onClickCniVerso={cniversoPrev !== null ? handleClickCniVerso : () => {}}
        />
      );

    case 3:
      return (
        <Step4
          nextStep={nextStep}
          previousStep={previousStep}
          handelSubmit={handelSubmit}
        />
      );

    default:
      return '';
    }
  };
  const steps = ['Auto', 'Offres', 'Information', 'Confirmation'];

  return (
    <div className="insurance-form">
      <AlertDialogOk open={openAlertDialogOk} handleClose={handleCloseAlertDialogOk} />
      <AlertDialog
        open={openPermiRectoDialog}
        handleClose={handleClosePermiRectoDialog}
        handleClick={handleClickPermiRectoDialog}
        filename="permi recto"
      />
      <AlertDialog
        open={openPermiVersoDialog}
        handleClose={handleClosePermiVersoDialog}
        handleClick={handleClickPermiVersoDialog}
        filename="permi verso"
      />
      <AlertDialog
        open={openCniRectoDialog}
        handleClose={handleCloseCniRectoDialog}
        handleClick={handleClickCniRectoDialog}
        filename="cni recto"
      />
      <AlertDialog
        open={openCniVersoDialog}
        handleClose={handleCloseCniVersoDialog}
        handleClick={handleClickCniVersoDialog}
        filename="cni verso"
      />
      <SimpleBackdrop open={openBackdrop} />
      <NavBar activeInd />
      <Loadbar steps={steps} stepr={stepCounter + 1} finalStep={4} />
      {getStep(stepCounter) }
      <div className="btns">

        <div className="btn-back">
          {stepCounter === 0 ? null
            : (
              <Button
                handelClick={() => {
                  previousStep(stepCounter, setStepCounter);
                  resetForm(stepCounter);
                }}
                reverse
              >
                BACK
              </Button>
            )}
        </div>
        <div className="btn-next">
          {stepCounter === 1 ? null : (
            <Button
              reverse={isDisabled(stepCounter)}
              disabled={isDisabled(stepCounter)}
              handelClick={stepCounter < 3 ? () => { nextStep(stepCounter, setStepCounter, 3); }
                : (e) => {
                  handelSubmit(e);
                }}
            >
              {stepCounter === 3 ? 'CONFIRMER' : 'NEXT'}
            </Button>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Footer />
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  SetcurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Insurance);
