import React from 'react';
import './stepperStyles.scss';

function Loadbar({ steps, stepr, finalStep }) {
  // const steps = ['VÉHICULE', 'FORMULE', 'OPTION', 'CONFIRMATION'];
  // const stepr = 2;
  // const finalStep = 4;
  return (
    <div className="stepper">
      {
        steps.map((step, index) => (
          <div key={Math.random() * 10} className={`step ${stepr === index + 1 ? 'currentStep' : ''} ${finalStep === index + 1 ? 'finalStep' : ''}`}>
            <div className="number">{index + 1}</div>
            <div className="label">
              {step}
            </div>
          </div>
        ))
      }
    </div>
  );
}

export default Loadbar;
