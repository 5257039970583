import React, { useState } from 'react';
import Button from '../../../components/buttons/Button/ButtonComponent';
import NavBar from '../../../components/NavBar/NavBarComponent';
import Footer from '../../../components/Footer/FooterComponent';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from '../../../components/finalStep/finalStepConponent';
import { nextStep, previousStep } from '../../../assets/constants/helpers/constants';
import Loadbar from '../../../components/stepper/stepperComponent';

export default function Insurance() {
  const [stepCounter, setStepCounter] = useState(0);

  const getStep = (Counter) => {
    switch (Counter) {
    case 0:
      return (
        <Step1
          nextStep={() => nextStep(stepCounter, setStepCounter, 2)}
          previousStep={() => previousStep(stepCounter, setStepCounter)}
        />
      );

    case 1:
      return (
        <Step2
          nextStep={() => nextStep(stepCounter, setStepCounter, 2)}
          previousStep={() => previousStep(stepCounter, setStepCounter)}
        />
      );

    case 2:
      return (
        <Step3
          nextStep={() => nextStep(stepCounter, setStepCounter, 2)}
          previousStep={() => previousStep(stepCounter, setStepCounter)}
        />
      );
    default:
      return '';
    }
  };
  const steps = ['assurè', 'Information', 'Confirmation'];

  return (
    <div className="insurance-form">
      <NavBar />
      <Loadbar steps={steps} stepr={stepCounter + 1} finalStep={3} />
      {getStep(stepCounter)}
      <div className="btns">
        <div className="btn-back">
          {stepCounter === 0 ? null : (<Button handelClick={() => previousStep(stepCounter, setStepCounter)} reverse> BACK </Button>)}
        </div>
        <div className="btn-next">
          <Button handelClick={() => nextStep(stepCounter, setStepCounter, 2)}> NEXT  </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
