import React, { useEffect, useState } from 'react';
import Input from '../../../../components/inputs/Input/InputComponent';
import './StepsStyles.scss';

export default function Step1({
  handelEnergy,
  Energy,
  handelPowerful,
  Powerful,
  handelNewValue,
  NewValue,
  handelDateOfCirculation,
  DateOfCirculation,
  sendIsValid,
}) {
  useEffect(() => {
    if (errorEnergy === null && errorPuissance === null && errorValeur === null && errorDate === null) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    sendIsValid(isValid);
  });

  function testInput(value) {
    if (value.trim() === '' || value === null || value === undefined) {
      return false;
    }
    return true;
  }

  function handleChange(value, message, setError) {
    const isValue = testInput(value);
    if (!isValue) {
      setError(message);
    } else {
      setError(null);
    }
  }

  const [errorEnergy, setErrorEnergy] = useState();
  const [errorPuissance, setErrorPuissance] = useState();
  const [errorValeur, setErrorValeur] = useState();
  const [errorDate, setErrorDate] = useState();
  const [isValid, setIsValid] = useState(false);

  return (
    <div className="step-form">
      <div className="group">
        <div className="field-1">
          <p className="Energie">Enérgie de votre véhicule</p>
          <select
            type="text"
            id="Energie"
            name="Energie"
            onChange={(e) => {
              handelEnergy(e);
              handleChange(e.target.value, 'le champ Energy est obligatoire', setErrorEnergy);
            }}
            onBlur={(e) => handleChange(e.target.value, 'le champ Energy est obligatoire', setErrorEnergy)}
            value={Energy}
          >
            <option value="essence">Essence</option>
            <option value="diesel">Diesel</option>
            <option value="electricite">Électricité</option>
            <option value="hybride">Hybride</option>
            <option value="hydrogene">Hydrogène</option>
            <option value="gaz">Gaz naturel</option>
          </select>
        </div>
        <Input
          type="text"
          id="Puissance"
          name="Puissance"
          label="Puissance fiscale"
          placeholder="Puissance fiscale"
          onChange={(e) => {
            handelPowerful(e);
            handleChange(e.target.value, 'le champ puissance fiscale est obligatoire', setErrorPuissance);
          }}
          onBlur={(e) => handleChange(e.target.value, 'le champ puissance fiscale est obligatoire', setErrorPuissance)}
          value={Powerful}
        />
      </div>
      <div className="group erreur">
        {errorEnergy && <span className="err">{`${errorEnergy} | `}</span>}
        {errorPuissance && <span className="err">{`${errorPuissance}`}</span>}
      </div>
      <div className="group">
        <Input
          type="text"
          id="Valeur"
          name="Valeur"
          label="Valeur à neuf"
          placeholder="Valeur à neuf"
          onChange={(e) => {
            handelNewValue(e);
            handleChange(e.target.value, 'le champ Valeur à neuf est obligatoire', setErrorValeur);
          }}
          onBlur={(e) => handleChange(e.target.value, 'le champ Valeur à neuf est obligatoire', setErrorValeur)}
          value={NewValue}
        />
        <Input
          type="date"
          id="date"
          name="date"
          label="Date de mise en circulation"
          placeholder="Date de mise en circulation"
          onChange={(e) => {
            handelDateOfCirculation(e);
            handleChange(e.target.value, 'le champ date de mise en circulation est obligatoire', setErrorDate);
          }}
          onBlur={(e) => handleChange(e.target.value, 'le champ date de mise en circulation est obligatoire', setErrorDate)}
          value={DateOfCirculation}
        />
      </div>
      <div className="group erreur">
        {errorValeur && <span className="err">{`${errorValeur} | `}</span>}
        {errorDate && <span className="err">{`${errorDate}`}</span>}
      </div>
    </div>
  );
}
