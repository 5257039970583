import React from 'react';

function Info({ children, title, width }) {
  return (
    <div className="blockInfo" style={{ width: `${width}%` }}>
      <div className="infoTitle"><p>{ title }</p></div>
      <div className="infoContent">
        {children}
      </div>
    </div>
  );
}

export default Info;
