import React from 'react';
import Logo2 from '../../assets/icons/logov2.png';
import './FooterStyles.scss';

export default function Footer({ reff }) {
  return (
    <footer className="groupes" ref={reff}>
      <div className="links">
        <div className="link">
          <span className="h3"> A propos </span>
        </div>
        <div className="link"> Mentions legales </div>
        <div className="link"> Conditions générales </div>
        <div className="link"> Respect de la vie privée </div>
      </div>
      <div className="links">
        <div className="link">
          <span className="h3">Assurances  </span>
        </div>
        <div className="link"> Entreprise </div>
        <div className="link">Individuel</div>
      </div>
      <div className="links">
        <div className="link">
          <span className="h3">  En savoir plus</span>
        </div>
        <div className="link">  Blog</div>
        <div className="link"> Nous ecrire </div>
        <div className="link"> Devenez partenaire </div>
      </div>
      <div className="links">
        <div className="link">
          <span className="h3">  Nous suivre</span>
        </div>
        <br />
        <br />
        <img src={Logo2} className="silyassuer" alt="" />
        <p className="copyright">© 2023 Silyassur</p>
      </div>
    </footer>
  );
}
