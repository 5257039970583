import React from 'react';
import { connect } from 'react-redux';
import Box from './box-table/boxComponent';
import './profileStyles.scss';

function Profile({ currentUser }) {
  return (
    <div className="profile-daitels">
      <Box
        title="Pièces jointes"
        subTitle="new attachment"
        header={['Nom du fichier', 'Date', 'Telechargé par ', 'Actions']}
        body={currentUser.attachments}
        callMessage="vous n'avez aucune pièce jointe"
      />
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(
  mapStateToProps,
  null,
)(Profile);
