import React, { useEffect, useState } from 'react';
import './StepsStyles.scss';
import Input from '../../../../components/inputs/Input/InputComponent';
import { ReactComponent as Upload } from '../../../../assets/images/upload.svg';

export default function Step3({
  handelStartDate,
  handelEndDate,
  handelPermirecto,
  handelpermiVerso,
  handelcnirecto,
  handelcniverso,
  permirectoPrev,
  permiversoPrev,
  cnirectoPrev,
  cniversoPrev,
  StartDate,
  EndDate,
  sendIsValid,
  permiRectoRef,
  permiVersoRef,
  cniRectoRef,
  cniVersoRef,
  onClickPermiRecto,
  onClickPermiVerso,
  onClickCniRecto,
  onClickCniVerso,
}) {
  useEffect(() => {
    function is4FilesNull() {
      if (
        (permirectoPrev === null || permirectoPrev === undefined)
        && (permiversoPrev === null || permiversoPrev === undefined)
        && (cnirectoPrev === null || cnirectoPrev === undefined)
        && (cniversoPrev === null || cniversoPrev === undefined)
      ) {
        setError4Files('merci d\'insérer un permi ou une cni');
      } else {
        setError4Files(null);
      }
    }
    is4FilesNull();
    if (errorDdc === null && errorDfc === null && errorPermi === null && errorCni === null && error4Files === null) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    sendIsValid(isValid);
  });

  function testInput(value) {
    if (value === '' || value === null || value === undefined) {
      return false;
    }
    return true;
  }

  function handleChange(value, message, setError) {
    const isValue = testInput(value);
    if (!isValue) {
      setError(message);
    } else {
      setError(null);
    }
  }

  function handleChangeFile(value, File2, File1, message, message2, setError) {
    const isValue = testInput(value);
    const isFile2 = testInput(File2);
    const isFile1 = testInput(File1);
    if (isFile2 && !isFile1 && !isValue) {
      setError(message);
    } else if (!isFile2 && isValue) {
      setError(message2);
    } else {
      setError(null);
    }
  }

  const [errorDdc, setErrorDdc] = useState();
  const [errorDfc, setErrorDfc] = useState();
  const [errorPermi, setErrorPermi] = useState(null);
  const [errorCni, setErrorCni] = useState(null);
  const [error4Files, setError4Files] = useState(null);
  const [isValid, setIsValid] = useState(false);

  return (
    <form className="step-form" id="form3">
      <div className="cart">
        <Input
          type="date"
          id="ddc"
          name="ddc"
          label="Date de début du contrat"
          placeholder="Date de mise en circulation"
          onChange={(e) => {
            handelStartDate(e);
            handleChange(e.target.value, 'le champ date début du contrat est obligatoire', setErrorDdc);
          }}
          onBlur={(e) => handleChange(e.target.value, 'le champ date début du contrat est obligatoire', setErrorDdc)}
          value={StartDate}
        />
        <Input
          type="date"
          id="dfc"
          name="dfc"
          label="Date de fin du contrat"
          onChange={(e) => {
            handelEndDate(e);
            handleChange(e.target.value, 'le champ date fin du contrat est obligatoire', setErrorDfc);
          }}
          onBlur={(e) => handleChange(e.target.value, 'le champ date fin du contrat est obligatoire', setErrorDfc)}
          value={EndDate}
        />
      </div>
      <div className="erreur">
        {errorDdc && <span className="err">{`${errorDdc} |`}</span>}
        {errorDfc && <span className="err">{errorDfc}</span>}
      </div>

      <div className="cart">
        <div className="wrapper-cart">
          <p className="text">Permi recto</p>
          <div className="recto">
            <label htmlFor="permi_recto">
              {permirectoPrev != null ? (
                <img src={permirectoPrev} alt="" className="image" />
              ) : (
                <div className="placeholder">
                  <Upload />
                  <div className="title1">
                    Drag & drop files or
                    {' '}
                    <span style={{ color: '#2263F3' }}>Browse</span>
                    {' '}
                  </div>
                  <div className="title2">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</div>
                </div>
              )}

              <input
                type="file"
                id="permi_recto"
                name="permi_recto"
                ref={permiRectoRef}
                onChange={(e) => {
                  handelPermirecto(e);
                  handleChangeFile(
                    e.target.value,
                    permiversoPrev,
                    permirectoPrev,
                    'le champ permi recto est obligatoire',
                    'le champ permi verso est obligatoire',
                    setErrorPermi,
                  );
                }}
                onClick={onClickPermiRecto}
              />
            </label>
          </div>
        </div>
        <div className="wrapper-cart">
          <p className="text">permi verso</p>
          <div className="verso">
            <label htmlFor="permi_verso">
              {permiversoPrev != null ? (
                <img src={permiversoPrev} alt="" className="image" />
              ) : (
                <div className="placeholder">
                  <Upload />
                  <div className="title1">
                    Drag & drop files or
                    {' '}
                    <span style={{ color: '#2263F3' }}>Browse</span>
                    {' '}
                  </div>
                  <div className="title2">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</div>
                </div>
              )}
              <input
                type="file"
                id="permi_verso"
                name="permi_verso"
                ref={permiVersoRef}
                onChange={(e) => {
                  handelpermiVerso(e);
                  handleChangeFile(
                    e.target.value,
                    permirectoPrev,
                    permiversoPrev,
                    'le champ permi verso est obligatoire',
                    'le champ permi recto est obligatoire',
                    setErrorPermi,
                  );
                }}
                onClick={onClickPermiVerso}
              />
            </label>
          </div>
        </div>
      </div>
      <div className="erreur">
        {errorPermi && <span className="err">{`${errorPermi}`}</span>}
      </div>

      <div className="cart">
        <div className="wrapper-cart">
          <p className="text">la carte CNI recto</p>
          <div className="recto">
            <label htmlFor="cni_recto">
              {cnirectoPrev != null ? (
                <img src={cnirectoPrev} alt="" className="image" />
              ) : (
                <div className="placeholder">
                  <Upload />
                  <div className="title1">
                    Drag & drop files or
                    {' '}
                    <span style={{ color: '#2263F3' }}>Browse</span>
                    {' '}
                  </div>
                  <div className="title2">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</div>
                </div>
              )}
              <input
                type="file"
                id="cni_recto"
                name="cni_recto"
                ref={cniRectoRef}
                onChange={(e) => {
                  handelcnirecto(e);
                  handleChangeFile(
                    e.target.value,
                    cniversoPrev,
                    cnirectoPrev,
                    'le champ cni recto est obligatoire',
                    'le champ cni verso est obligatoire',
                    setErrorCni,
                  );
                }}
                onClick={onClickCniRecto}
              />
            </label>
          </div>
        </div>
        <div className="wrapper-cart">
          <p className="text">la carte CNI verso</p>
          <div className="verso">
            <label htmlFor="cni_verso">
              {cniversoPrev != null ? (
                <img src={cniversoPrev} alt="" className="image" />
              ) : (
                <div className="placeholder">
                  <Upload />
                  <div className="title1">
                    Drag & drop files or
                    {' '}
                    <span style={{ color: '#2263F3' }}>Browse</span>
                    {' '}
                  </div>
                  <div className="title2">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</div>
                </div>
              )}
              <input
                type="file"
                id="cni_verso"
                name="cni_verso"
                ref={cniVersoRef}
                onChange={(e) => {
                  handelcniverso(e);
                  handleChangeFile(
                    e.target.value,
                    cnirectoPrev,
                    cniversoPrev,
                    'le champ cni verso est obligatoire',
                    'le champ cni recto est obligatoire',
                    setErrorCni,
                  );
                }}
                onClick={onClickCniVerso}
              />
            </label>
          </div>
        </div>
      </div>
      <div className="erreur">
        {errorCni && <span className="err">{`${errorCni}`}</span>}
        {error4Files && <span className="err">{`${error4Files}`}</span>}
      </div>
    </form>
  );
}
