import React from 'react';
import './finalStepStyles.scss';

import { ReactComponent as Congrat } from '../../assets/images/congrat.svg';

export default function FinalStep() {
  return (
    <div className="step-form">
      <div className="group-2">
        <Congrat />
        <div className="cong-text">
          <span className="feles">Félicitations</span>
          , vous allez recevoir par email votre devis
        </div>
      </div>
    </div>
  );
}
