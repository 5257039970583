import React, { useState } from 'react';
import './LoginInputStyles.scss';
import { ReactComponent as EmailIcon } from '../../../assets/icons/email-input.svg';
import { ReactComponent as PasswordIcon } from '../../../assets/icons/password-input.svg';
import { ReactComponent as Eye } from '../../../assets/icons/eye.svg';

export default function LoginInput({
  label,
  id,
  fullwidth,
  required,
  type,
  useIcon,
  placeholder,
  onChange,
}) {
  const [typep, setype] = useState(type === 'password' ? 'password' : 'text');
  const handelTypePassword = () => {
    if (typep === 'password') {
      setype('text');
    } else {
      setype('password');
    }
  };
  return (
    <div className={`field-login ${fullwidth ? 'full-width' : ''} ${required ? 'required' : ''}`}>
      <label htmlFor={id}>{label}</label>
      <input required={required} type={typep} placeholder={placeholder} onChange={onChange} />
      {
        useIcon && (type === 'password' ? (<PasswordIcon className="icon" />) : (<EmailIcon className="icon" />))
      }
      {
        type === 'password' && useIcon && <Eye className="icon2" onClick={handelTypePassword} />
      }
    </div>
  );
}
