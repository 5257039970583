import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Alert, Collapse } from '@mui/material';
import './contactStyles.scss';
import Input from '../../../../components/inputs/Input/InputComponent';
import Button from '../../../../components/buttons/Button/ButtonComponent';
import getEnvironement from '../../../../../../environement';

const contact = ({ currentUser }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [open, setOpen] = useState(false);

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleContentChange = (e) => setContent(e.target.value);

  const sendMessage = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Accept: 'application/json',
      },
    };

    const body = {
      sender: {
        nom: currentUser.last_name,
        prenom: currentUser.first_name,
        email: currentUser.email,
      },
      title,
      content,
    };
    const url = `${getEnvironement().API_URL}/clt/contact`;
    axios.post(url, body, config).then(() => { setOpen(true); }).then(() => { setTimeout(() => setOpen(false), 3000); });
  };
  return (
    <div className="contact-page">
      <Collapse in={open} className="alert">
        <Alert severity="success">le message a bien été envoyé</Alert>
      </Collapse>
      <form action="">
        <Input label="Sujet :" placeholder="Sujet" value={title} onChange={(e) => handleTitleChange(e)} />
        <label htmlFor="message">
          <textarea placeholder="Message" id="message" rows={10} cols={20} value={content} onChange={(e) => handleContentChange(e)} />
        </label>
        <Button handelClick={(e) => sendMessage(e)}>
          Envoyer
        </Button>
      </form>
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(
  mapStateToProps,
  null,
)(contact);
