import React from 'react';
import { connect } from 'react-redux';
import './boxStyles.scss';
import getEnvironement from '../../../../../../../environement';

function Box({
  title, header, body, callMessage, total, currentUser,
}) {
  return (
    <div className="box-profile">

      <div className="header">
        <div className="title">
          {' '}
          {title}
        </div>
      </div>
      <br />
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {
                header.map((thE) => (<th key={thE}>{thE}</th>))
              }
            </tr>
          </thead>
          <tbody>

            {
              body.length > 0
                ? body.map((attachment) => (
                  <tr key={attachment.id}>
                    <td>{attachment.name}</td>
                    <td>{attachment.created_at.split(' ')[0]}</td>
                    <td>
                      {currentUser.first_name}
                      {' '}
                      {currentUser.last_name}
                    </td>
                    <td>
                      <a href={`${getEnvironement().BACKEND_URL}/storage/app/attachments/${attachment.filename}`}>
                        <span className="btn"> View</span>
                      </a>
                    </td>
                  </tr>
                ))
                : (
                  <tr>
                    <td colSpan={header.length}>
                      {callMessage}
                      {' '}
                    </td>
                  </tr>
                )
            }

            {
              total && (
                <tr>
                  <td className="text-start" colSpan={3}>total</td>

                  <td className="text-start">Tko</td>
                  <td className="text-start" colSpan={5}>Tko</td>

                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(
  mapStateToProps,
  null,
)(Box);
