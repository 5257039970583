import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './checkLoginStyles.scss';
import { Link, useNavigate } from 'react-router-dom';
import Nav from '../NavBar/NavBarComponent';

function CheckLoginEntreprise({
  image,
  title,
  text,
  type,
  currentUser,
  url,
  individuel,
  entreprise,
}) {
  const Navigate = useNavigate();
  useEffect(() => {
    if (currentUser) Navigate(url);
  }, []);
  return (
    <div className="check-login-2" style={{ backgroundImage: `url(${image})` }}>
      <Nav transparent activeInd={individuel} activeEnt={entreprise} />
      <div className="header">
        <div className="text">
          <div className="sub-title">{type}</div>
          <div className="title">
            {title}
          </div>
          <div className="sub-title">
            {text}
          </div>
          <div className="buttons">
            <Link to="/auth/login" className="btn">Se connecter   </Link>
            <Link to="/registration" className="btn sc"> Crée un compte </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(
  mapStateToProps,
  null,
)(CheckLoginEntreprise);
