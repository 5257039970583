import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './EntrepriseStyles.scss';
import './Scss/Header.scss';
import './Scss/Links.scss';
import './Scss/Description.scss';
import NavBarComponent from '../../components/NavBar/NavBarComponent';
import MemberOnline from '../../assets/icons/memberOnline.svg';
import { ReactComponent as EntrepriseHeader } from '../../assets/images/entrepriseHeader.svg';
import OkayIcon from '../../assets/icons/ok.svg';
import { ReactComponent as Auto } from '../../assets/icons/auto.svg';
import { ReactComponent as Moto } from '../../assets/icons/moto.svg';
import { ReactComponent as Route } from '../../assets/icons/route.svg';
import { ReactComponent as House } from '../../assets/icons/house.svg';
import { ReactComponent as Avion } from '../../assets/icons/avion.svg';
import { ReactComponent as Core } from '../../assets/icons/core.svg';
import { ReactComponent as Eparnge } from '../../assets/icons/eparnge.svg';
import { ReactComponent as Loisir } from '../../assets/icons/loisir.svg';
import { ReactComponent as Shape } from '../../assets/icons/shape.svg';
import { ReactComponent as Entreprise } from '../../assets/icons/entreprise.svg';
import { ReactComponent as Setting } from '../../assets/icons/setting.svg';
import Footer from '../../components/FooterFull/FooterFullComponent';

export default function EntrepriseView() {
  const [mode, setMode] = useState(1);
  const [fixed, setFixed] = useState(false);
  const getLinks = (_mode) => {
    switch (_mode) {
    case 1:
      return (
        <div className="wrapper-links">
          <Link to="/assurmultipro" className="link">
            <Auto className="icon" />
            <span className="name">Assur&apos;Multi Pro </span>
          </Link>
          <Link to="/gestionFlotte" className="link">
            <Moto className="icon" />
            <span className="name">Gestion de flotte </span>
          </Link>
          <Link to="/gestionFlotteConnectee" className="link">
            <Avion className="icon" />
            <span className="name">Gestion de flotte connectée </span>
          </Link>
          <Link to="/transportMarchandise" className="link">
            <Core className="icon" />
            <span className="name">Transport de marchandise </span>
          </Link>
          <Link to="/navirePeche" className="link">
            <Eparnge className="icon" />
            <span className="name">Corps et navire de pêche </span>
          </Link>
          <Link to="/assurMoukawalati" className="link">
            <Loisir className="icon" />
            <span className="name">Assur&apos;Moukawalati </span>
          </Link>
        </div>
      );
    case 2:
      return (
        <div className="wrapper-links">
          <Link to="/autoSalarie" className="link">
            <Auto className="icon" />
            <span className="name">Auto </span>
          </Link>
          <Link to="/motoSalarie" className="link">
            <Moto className="icon" />
            <span className="name">Moto </span>
          </Link>
          <Link to="/accidentSalarie" className="link">
            <Route className="icon" />
            <span className="name">Accident </span>
          </Link>
          <Link to="/abitationSalarie" className="link">
            <House className="icon" />
            <span className="name">Abitation </span>
          </Link>
          <Link to="/epargneSalarie" className="link">
            <Eparnge className="icon" />
            <span className="name">Epargne </span>
          </Link>
          <Link to="/loisirSalarie" className="link">
            <Loisir className="icon" />
            <span className="name">Loisir </span>
          </Link>
        </div>
      );
    case 3:
      return (
        <div className="wrapper-links">
          <Link to="/accidentCivile" className="link">
            <Route className="icon" />
            <span className="name">Accident </span>
          </Link>
          <Link to="/abitationCivile" className="link">
            <House className="icon" />
            <span className="name">Abitation </span>
          </Link>
          <Link to="/voyageCivile" className="link">
            <Avion className="icon" />
            <span className="name">Voyage </span>
          </Link>
          <Link to="/santeCivile" className="link">
            <Core className="icon" />
            <span className="name">Santé Prévoyance </span>
          </Link>
          <Link to="/epargneCivile" className="link">
            <Eparnge className="icon" />
            <span className="name">Epargne </span>
          </Link>
          <Link to="/loisirCivile" className="link">
            <Loisir className="icon" />
            <span className="name">Loisir </span>
          </Link>
        </div>
      );
    default:
      return (<> </>);
    }
  };
  const handleResize = () => {
    if (window.scrollY > 480) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };
  useEffect(() => {
    window.onscroll = () => {
      handleResize();
    };
    return () => {
    };
  }, []);

  return (
    <div className="entreprise-page">
      <NavBarComponent />
      <div className="header entreprise">
        <div className="text">
          <div className="title">
            L&apos;assurance professionnelle
            adaptée à votre activité.
          </div>
          <div className="sub-title">Souscrire et gérer vos contrats n&apos;a jamais été aussi simple. Silyassur se charge de tout.</div>
          <div className="secend-text">Découvrez toutes nos assurances</div>
        </div>
        <div className="image">
          <EntrepriseHeader className="man" />
        </div>
        <div className="message-love">
          <img src={OkayIcon} alt="" className="img" />
          <div className="avi">
            <span className="change-color">Contacter</span>
            <span className="change-color">les</span>
            <span className="change-color">interlocuteurs</span>
            <span className="change-color">Silyassur</span>
            <img src={MemberOnline} alt="" className="member" />
          </div>
        </div>
        <div className="message-rating">
          <div className="avi">
            <span className="change-color">Multirisque</span>
            <span className="change-color">et</span>
            <span className="change-color">monorisque</span>
            <span className="change-color">professionnelle</span>
          </div>
        </div>
      </div>
      <div className={`insurances-links ${fixed ? 'fixed' : ''} `} onScroll={handleResize}>
        <div className="mode">
          <button type="submit" className={`option ${mode === 1 ? 'active' : ''}`} onClick={() => setMode(1)}> Protection des biens</button>
          <button type="submit" className={`option ${mode === 2 ? 'active' : ''}`} onClick={() => setMode(2)}> Protection des salariés</button>
          <button type="submit" className={`option ${mode === 3 ? 'active' : ''}`} onClick={() => setMode(3)}> Responsabilité civile</button>
        </div>
        {getLinks(mode)}

      </div>
      <div className="description">
        <div className="title">
          <span className="center">De quoi s&apos;agit-il ?</span>
        </div>
        <div className="detailes">
          <div className="wraper">
            <span className="text">
              Les assurances pour l&apos;entreprise protègent
              vos activités professionnelles et celles de vos employés.
              Elles permettent de garantir les risques liés à
              l&apos;exercice de votre activité et à la vie courante de l&apos;entreprise.
            </span>
          </div>
          <div className="wraper">
            <span className="text">
              Elles vous couvrent par exemple en cas de manquements
              contractuels, de faute professionnelle, d&apos;omission, de piratage de site internet,
              ou d&apos;agissements accidentels de vos employés.
            </span>
          </div>
        </div>
      </div>
      <div className="desc">
        <Shape className="shape" />
        <div className="wrapper">
          <div className="title">
            Couvrir l&apos;ensemble des risques d&apos;entreprise
          </div>
          <div className="text">
            Apportez une réponse
            ciblée aux besoins des entreprises avec notre
            gamme complète de produits.
          </div>
        </div>
      </div>
      <section className="our-insurances">
        <div className="title">
          <span className="center">
            Nos assurances
            <br />
            Entreprises & Individuel
          </span>
          <Shape className="shape" />
        </div>
        <div className="insurances">
          <div className="insurance">
            <div className="logo">
              <Entreprise className="icon" />
            </div>
            <div className="name"> Assurances Entreprises</div>
            <div className="description">
              Ce sont les assurances qui protègent vos activités et vos employés.
              Elles permettent de garantir les risques liés à l’exercice de votre activité.
            </div>
            <span className="more">Pour savoir plus... </span>
          </div>
          <div className="insurance">
            <div className="logo indiv">
              <Setting className="icon" />
            </div>
            <div className="name">Assurances Individuel</div>
            <div className="description">
              Ce sont les assurances qui protègent
              les indépendants, qui ne bénéficient pas des mêmes conditions
              de protection que les salariés.
            </div>
            <span className="more">Pour savoir plus... </span>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
