import React from 'react';
import { connect } from 'react-redux';
import PaymentBox from './box-table/PaymentBoxComponent copy';
import './profileStyles.scss';
import setCurrentUser from '../../../../../db/redux/user/user-actions';

function Facturation({ currentUser, SetcurrentUser }) {
  return (
    <div className="profile-daitels">

      <PaymentBox
        title="Paiements"
        subTitle="Ajouter un paiement"
        header={['#No', 'Assurance', 'Montant', 'Date', 'Méthode']}
        body={currentUser.payments}
        listAssurances={currentUser.policies}
        callMessage="vous n'avez effectué aucun paiement"
        CurrentUserId={currentUser.id}
        SetcurrentUser={SetcurrentUser}
      />
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  SetcurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Facturation);
