import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({ open, handleClose, filename, handleClick }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          { `Voulez-vous vraiment modifier l'image ${filename}?` }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            l&apos;ancienne image sera supprimer définitivement.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Annuler </Button>
          <Button onClick={handleClick} autoFocus>
            Continuer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
