import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import FilterAlt from '@mui/icons-material/FilterAlt';
import Sort from '@mui/icons-material/Sort';
import './assurancesStyles.scss';

function Assurances({ currentUser, policies }) {
  // eslint-disable-next-line no-console
  console.log(policies);
  return (
    <div className="assurances-container">
      <div className="header">
        <div className="title"> Tous les assurances</div>
        <Link to="/">
          <Button color="primary" aria-label="home button" variant="contained">
            Ajouter une nouvelle assurance
          </Button>
        </Link>
      </div>
      <div className="header">
        <div />
        <div className="options">

          <div className="option">
            <Sort className="icon" />
            <div className="name">Trier</div>
          </div>

          <div className="option">
            <FilterAlt className="icon" />
            <div className="name">Filtrer</div>
          </div>
        </div>
      </div>
      <div className="assurances-table">
        <table>
          <thead>
            <tr>
              <th>Ref NO.</th>
              <th>Produit</th>
              <th>Assureur</th>
              <th>Date</th>
              <th>Statut</th>
              <th>{}</th>
            </tr>
          </thead>
          <tbody>

            {/* <tr>
                    <td>LM5TDU4A</td>
                    <td>Insurance Home</td>
                    <td>
                      Wafa incurance
                      <br />
                      {' '}
                      <span className="type">Entreprise</span>
                      {' '}
                    </td>
                    <td>
                      May 26,2019
                      <br />
                      <span className="houre">8:30 PM</span>
                      {' '}
                    </td>
                    <td>
                      {' '}
                      <span className="status done"> paid </span>
                      {' '}
                    </td>
                    <td>
                      {' '}
                      <MoreVert />
                      {' '}
                    </td>
                  </tr> */}
            {/*                      map   */}
            {
              currentUser.policies.map((policy) => (
                <tr key={policy.id}>
                  <td>{policy.ref_no}</td>
                  <td>{policy.product.category}</td>
                  <td>
                    {policy.product.insurer}
                    <br />
                    <span className="type">{currentUser.client_type}</span>
                  </td>
                  <td>
                    {policy.created_at.split(' ')[0]}
                    <br />
                    <span className="houre">
                      {policy.created_at.split(' ')[1]}
                    </span>
                  </td>
                  <td>
                    { policy.premium <= policy.paid && (<span className="status paid"> paid</span>)}
                    { policy.premium > policy.paid && policy.paid > 0 && (<span className="status partial"> partial</span>)}
                    { policy.premium > policy.paid && policy.paid === 0 && (<span className="status unpaid"> unpaid</span>)}
                  </td>
                  <td>
                    <MoreVert />
                  </td>
                </tr>
              ))
            }

          </tbody>
        </table>
      </div>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(
  mapStateToProps,
  null,
)(Assurances);
