import React from 'react';
import './ProfileStyles.scss';
import { Outlet } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

// import Logo from '../../assets/icons/logov2.png';
import NavBar from './sections/NavBar/navbarComponent';
import Sidebar from './sections/SideBar/sidebarComponent';

function ProfileView() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (openDrawer) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(openDrawer);
  };
  return (
    <div className="profile-page">
      <div className="sidebarsup700">
        <Sidebar setOpenDrawer={() => {}} />
      </div>
      <div>
        <React.Fragment key="top">
          <Button className="menu" onClick={toggleDrawer(true)}>
            <MenuOutlinedIcon fontSize="large" sx={{ color: 'white' }} />
          </Button>
          <Drawer
            anchor="top"
            open={open}
            onClose={toggleDrawer(false)}
          >
            <div className="sidebarinf700">
              <Sidebar setOpenDrawer={setOpen} />
            </div>
          </Drawer>
        </React.Fragment>
      </div>

      <section className="profile-section">

        <NavBar />

        <div className="profile-content">

          <div className="profile-content-container">
            {/*  Outlet  */}
            <Outlet />
            <p className="copyright">© 2023 Silyassur</p>
            {/*  */}
          </div>

        </div>

      </section>

    </div>
  );
}

export default ProfileView;
