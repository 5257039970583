import React from 'react';
import './StepsStyles.scss';
import CheckboxToggle from '../../../../components/inputs/checkboxToggle/checkboxToggleComponent';
import { ReactComponent as Pomme } from '../../../../assets/icons/pomme.svg';
import { ReactComponent as Orange } from '../../../../assets/icons/orange.svg';
import { ReactComponent as Kiwi } from '../../../../assets/icons/kiwi.svg';

export default function step1() {
  return (
    <div className="step-form-ac">
      <div className="options-ac">
        <div className="option-ac">
          <div className="box-v2">
            <div className="option-name">
              <Pomme />
              <h2>Formule Pomme</h2>
            </div>
          </div>
          <div className="box-v2 br">
            <div className="title">
              Décés accidentel
            </div>
            <div className="ul">
              <div className="li">
                Capital décés accidental
                <br />
                <span className="price"> 50 000 DHS </span>
              </div>
            </div>
          </div>
          <div className="box-v2 br">
            <div className="title">
              Frais medicaux,
              <br />
              pharmaceutiques chirigicaux
            </div>
            <div className="ul">
              <div className="li">
                Taux de remboursement
                <br />
                <span className="price">80% </span>
              </div>
              <div className="li">
                Plafond par an et par assuré
                <br />
                <span className="price"> Franchise </span>
              </div>
              <div className="li">
                Capital décés accidental
                <br />
                <span className="price"> 50 000 DHS </span>
              </div>
            </div>
          </div>
          <div className="box-v2">
            <div className="title">
              Indéminités journaliéres
              <br />
              d&apos;hospitalisation
            </div>
            <div className="ul">
              <div className="li">
                Indéminités journaliéres
                <br />
                <span className="price"> 150 DHS </span>
              </div>
              <div className="li">
                Prime annuelle TTC
                <br />
                <span className="price"> 312,50 DHS </span>
              </div>
            </div>
          </div>
          <div className="box-green">
            <div className="wrapper br">
              <div className="title">
                Assistance
              </div>
              <div className="opt">
                <CheckboxToggle />
                <span className="label"> Assistance </span>
              </div>
            </div>
            <div className="wrapper br">
              <div className="title">
                Ecoute psychologique
              </div>
              <div className="opt">
                <CheckboxToggle />
                <span className="label"> 3 séances/accident </span>
              </div>
            </div>
            <div className="wrapper br">
              <div className="title">
                Conseil juridique
              </div>
              <div className="opt">
                <CheckboxToggle />
                <span className="label"> Une consultaton / accident </span>
              </div>
            </div>
            <div className="wrapper">
              <div className="title">
                Prime Totale
              </div>
              <div className="opt">
                <span className="price"> 312.50 DHS TTC </span>
              </div>
            </div>
          </div>
          <div className="commander">
            COMMANDER
          </div>
        </div>
        <div className="option-ac orange">
          <div className="box-v2">
            <div className="option-name">
              <Orange />
              <h2>Formule Orange</h2>
            </div>
          </div>
          <div className="box-v2 br">
            <div className="title">
              Décés accidentel
            </div>
            <div className="ul">
              <div className="li">
                Capital décés accidental
                <br />
                <span className="price"> 50 000 DHS </span>
              </div>
            </div>
          </div>
          <div className="box-v2 br">
            <div className="title">
              Frais medicaux,
              <br />
              pharmaceutiques chirigicaux
            </div>
            <div className="ul">
              <div className="li">
                Taux de remboursement
                <br />
                <span className="price">80% </span>
              </div>
              <div className="li">
                Plafond par an et par assuré
                <br />
                <span className="price"> Franchise </span>
              </div>
              <div className="li">
                Capital décés accidental
                <br />
                <span className="price"> 50 000 DHS </span>
              </div>
            </div>
          </div>
          <div className="box-v2">
            <div className="title">
              Indéminités journaliéres
              <br />
              d&apos;hospitalisation
            </div>
            <div className="ul">
              <div className="li">
                Indéminités journaliéres
                <br />
                <span className="price"> 150 DHS </span>
              </div>
              <div className="li">
                Prime annuelle TTC
                <br />
                <span className="price"> 312,50 DHS </span>
              </div>
            </div>
          </div>
          <div className="box-green">
            <div className="wrapper br">
              <div className="title">
                Assistance
              </div>
              <div className="opt">
                <CheckboxToggle orange />
                <span className="label"> Assistance </span>
              </div>
            </div>
            <div className="wrapper br">
              <div className="title">
                Ecoute psychologique
              </div>
              <div className="opt">
                <CheckboxToggle orange />
                <span className="label"> 3 séances/accident </span>
              </div>
            </div>
            <div className="wrapper br">
              <div className="title">
                Conseil juridique
              </div>
              <div className="opt">
                <CheckboxToggle orange />
                <span className="label"> Une consultaton / accident </span>
              </div>
            </div>
            <div className="wrapper">
              <div className="title">
                Prime Totale
              </div>
              <div className="opt">
                <span className="price"> 312.50 DHS TTC </span>
              </div>
            </div>
          </div>

          <div className="commander orange-b">
            COMMANDER
          </div>
        </div>
        <div className="option-ac kiwi">
          <div className="box-v2">
            <div className="option-name">
              <Kiwi />
              <h2>Formule Kiwi</h2>
            </div>
          </div>
          <div className="box-v2 br">
            <div className="title">
              Décés accidentel
            </div>
            <div className="ul">
              <div className="li">
                Capital décés accidental
                <br />
                <span className="price"> 50 000 DHS </span>
              </div>
            </div>
          </div>
          <div className="box-v2 br">
            <div className="title">
              Frais medicaux,
              <br />
              pharmaceutiques chirigicaux
            </div>
            <div className="ul">
              <div className="li">
                Taux de remboursement
                <br />
                <span className="price">80% </span>
              </div>
              <div className="li">
                Plafond par an et par assuré
                <br />
                <span className="price"> Franchise </span>
              </div>
              <div className="li">
                Capital décés accidental
                <br />
                <span className="price"> 50 000 DHS </span>
              </div>
            </div>
          </div>
          <div className="box-v2">
            <div className="title">
              Indéminités journaliéres
              <br />
              d&apos;hospitalisation
            </div>
            <div className="ul">
              <div className="li">
                Indéminités journaliéres
                <br />
                <span className="price"> 150 DHS </span>
              </div>
              <div className="li">
                Prime annuelle TTC
                <br />
                <span className="price"> 312,50 DHS </span>
              </div>
            </div>
          </div>
          <div className="box-green">
            <div className="wrapper br">
              <div className="title">
                Assistance
              </div>
              <div className="opt">
                <CheckboxToggle kiwi />
                <span className="label"> Assistance </span>
              </div>
            </div>
            <div className="wrapper br">
              <div className="title">
                Ecoute psychologique
              </div>
              <div className="opt">
                <CheckboxToggle kiwi />
                <span className="label"> 3 séances/accident </span>
              </div>
            </div>
            <div className="wrapper br">
              <div className="title">
                Conseil juridique
              </div>
              <div className="opt">
                <CheckboxToggle kiwi />
                <span className="label"> Une consultaton / accident </span>
              </div>
            </div>
            <div className="wrapper">
              <div className="title">
                Prime Totale
              </div>
              <div className="opt">
                <span className="price"> 312.50 DHS TTC </span>
              </div>
            </div>
          </div>

          <div className="commander kiwi-b">
            COMMANDER
          </div>
        </div>
      </div>

    </div>
  );
}
