import React from 'react';
import Input from '../../../../components/inputs/Input/InputComponent';
import './StepsStyles.scss';

export default function Step1() {
  return (
    <div className="step-form-moto">
      <div className="group grid">
        <label htmlFor="marque">
          marque
          <select name="souscription[marque]" id="marque">
            <option value="Yamaha">Yamaha</option>
            <option value="Honda">Honda</option>
            <option value="Kawasaki">Kawasaki</option>
            <option value="BMW">BMW</option>
            <option value="Suzuki">Suzuki</option>
            <option value="Triumph">Triumph</option>
            <option value="Piaggio">Piaggio</option>
            <option value="Ducati">Ducati</option>
            <option value="Harley Davidson">Harley Davidson</option>
            <option value="Kymco">Kymco</option>
            <option value="KTM">KTM</option>
            <option value="Aprilia">Aprilia</option>
            <option value="MBK">MBK</option>
            <option value="PEUGEOT">PEUGEOT</option>
            <option value="Benelli">Benelli</option>
            <option value="Beta">Beta</option>
            <option value="CFMOTO">CFMOTO</option>
            <option value="SYM">SYM</option>
            <option value="XCBLY">XCBLY</option>
            <option value="DOCKER">DOCKER</option>
            <option value="XCBLY">XCBLY</option>
            <option value="DOCKER">DOCKER</option>
            <option value="XCBLY">XCBLY</option>
            <option value="F I C E">F I C E</option>
            <option value="CIMATTI">CIMATTI</option>
            <option value="BECANE">BECANE</option>
            <option value="GUZZI">GUZZI</option>
            <option value="PHOENIX">PHOENIX</option>
          </select>
        </label>
        <label htmlFor="puissance_fiscale">
          la classe
          <select name="souscription[puissance_fiscale]" id="puissance_fiscale">
            <option value="0">la classe</option>
            <option value="1">D1 Sc1 Bicyclettes et Vélomoteurs &lt;= 50 cm3 et &lt;=60 Km/h </option>
            <option value="1">D1 Sc1 Bicyclettes et Vélomoteurs &lt;= 50 cm3 et&gt; 60 Km/h  </option>
            <option value="2">D1 Sc3 Véhicules à 2 Roues Avec Cylindrée entre 50 et 125 cm3  </option>
            <option value="3">D1 Sc4 Véhicules à 3 Roues Avec Cylindrée &lt;= 125 cm3        </option>
            <option value="4">D1 Sc5 Véhicules à 2 Roues Avec Cylindrée &gt; 125 cm3         </option>
            <option value="5">D1 Sc6 Véhicules à 3 Roues Avec Cylindrée &gt; 125 cm3         </option>
          </select>
        </label>
      </div>
      <div className="group">
        <Input type="date" id="date" label="Date de début du contrat" />
      </div>
    </div>
  );
}
