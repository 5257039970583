import React, { useState } from 'react';
import { ReactComponent as ModeIcon } from '../../../../assets/icons/mode-pay.svg';
import './StepsStyles.scss';
import { ReactComponent as CartIcon } from '../../../../assets/icons/cart-credit.svg';
import { ReactComponent as Drop } from '../../../../assets/icons/drop.svg';
import Button from '../../../../components/buttons/Button/ButtonComponent';

export default function step3() {
  const [curentOptionOpened, setCurentOptionOpened] = useState('');
  return (
    <div className="step-form-ac">
      <div className="title-mode">
        <div className="icon-container">
          <ModeIcon className="icon" />
        </div>
        <div className="text">Mode de Paiement</div>
      </div>
      <div className="contianer-list">
        <div className="rapper-list">

          <button
            type="submit"
            className={` list ${curentOptionOpened === 1 ? 'open' : ''}`}
            onClick={() => setCurentOptionOpened(curentOptionOpened === 1 ? 0 : 1)}
          >
            <CartIcon className="icon" />
            <div className="title"> Par cate bancaire </div>
            <Drop className="drop" />
          </button>
          <div className={` drop-down-toggle ${curentOptionOpened === 1 ? 'open' : ''}`}>
            Paiement en toute sécurité par carte bancaire / en ligne CMI
            Merci de procéder à la validation des conditions générales pour pouvoir compléter votre achat
            <Button>COMANDER</Button>
          </div>
        </div>
        <div className="rapper-list">
          <button
            type="submit"
            className={` list ${curentOptionOpened === 2 ? 'open' : ''}`}
            onClick={() => setCurentOptionOpened(curentOptionOpened === 2 ? 0 : 2)}
          >
            <CartIcon className="icon" />
            <div className="title"> Par chéque </div>
            <Drop className="drop" />
          </button>
          <div className={` drop-down-toggle ${curentOptionOpened === 2 ? 'open' : ''}`}>
            Le chèque doit être libellé à l&apos;ordre de Silyassur
            <Button>COMANDER</Button>
          </div>
        </div>
        <div className="rapper-list">
          <button
            type="submit"
            className={` list ${curentOptionOpened === 3 ? 'open' : ''}`}
            onClick={() => setCurentOptionOpened(curentOptionOpened === 3 ? 0 : 3)}
          >
            <CartIcon className="icon" />
            <div className="title"> Par virement </div>
            <Drop className="drop" />
          </button>
          <div className={` drop-down-toggle ${curentOptionOpened === 3 ? 'open' : ''}`}>
            Merci de prodéder au virement de votre prime d&apos;assurance
            <br />
            RIB : 19078xxxxxxxxxxxxxxxx08 13
            <br />
            Code swift : BCP OMAMC
            <br />
            <Button>COMANDER</Button>

          </div>
        </div>
        <div className="rapper-list">

          <button
            type="submit"
            className={` list ${curentOptionOpened === 4 ? 'open' : ''}`}
            onClick={() => setCurentOptionOpened(curentOptionOpened === 4 ? 0 : 4)}
          >
            <CartIcon className="icon" />
            <div className="title"> Paiement sur place </div>
            <Drop className="drop" />
          </button>
          <div className={` drop-down-toggle ${curentOptionOpened === 4 ? 'open' : ''}`}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea consequuntur
            quisquam, amet temporibus ullam alias! Blanditiis, aliquid? Assumenda, ipsa nesciunt.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea consequuntur
            <Button>COMANDER</Button>

          </div>
        </div>
      </div>
      <div className="wrapper-text">
        consectetur adipiscing elit duis tristique sollicitudin nibh sit
        amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae
        congueconsectetur adipiscing elit duis tristique sollicitudin nibh sit
        amet commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae congue
      </div>
      <div className="wrapper-conditions">
        <div className="condition">
          <input type="checkbox" name="" id="" />
          <span className="label"> j&apos;accepte les condition générales </span>
        </div>
        <div className="condition">
          <input type="checkbox" name="" id="" />
          <span className="label"> j&apos;accepte les condition générales himayati d’Allianz </span>
        </div>
        <div className="condition">
          <input type="checkbox" name="" id="" />
          <span className="label"> Résumé des conditions particuliéres </span>
        </div>
      </div>
    </div>
  );
}
