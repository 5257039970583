const ENVIRONMENT = {
  developpement: {
    ASPSP: 'dev',
    API_URL: 'http://localhost:81/silyassuradminapp/api',
    BACKEND_URL: 'http://localhost:81/silyassuradminapp',
  },
  production: {
    ASPSP: 'prod',
    API_URL: 'https://silyassurassurance.silyassur.com/api',
    BACKEND_URL: 'https://silyassurassurance.silyassur.com',
  },
};

const PLATFORM = 'production';

export default function getEnvironement() {
  return ENVIRONMENT[PLATFORM];
}
