import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

export default function RouteNavigator({ config, DefaultView }) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<DefaultView />} />
          {constructRoutes(config)}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function constructRoutes(config) {
  const result = [];
  const layoutConfigItems = Object.entries(config);
  layoutConfigItems.forEach(([layoutAlias, viewConfigItems]) => {
    result.push((
      <Route key={layoutAlias} path={layoutAlias}>
        {
          viewConfigItems.map(({ path, Component, nested }) => (
            <Route
              key={`${layoutAlias}/${path}`}
              path={path}
              element={<Component />}
            >
              { nested && nested.map(({ subPath, SubComponent }) => (
                <Route
                  key={`${layoutAlias}/${subPath}`}
                  path={subPath}
                  element={<SubComponent />}
                />
              )) }
            </Route>
          ))
        }
      </Route>
    ));
  });
  return result;
}
