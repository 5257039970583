import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './RegistrationStyles.scss';
// import axios from 'axios';
import Button from '../../components/buttons/Button/ButtonComponent';
import Input from '../../components/inputs/RegisterInput/RegisterInputComponent';
import NavBar from '../../components/NavBar/NavBarComponent';
import LogoTransp from '../../assets/images/logo-s-transparent.png';
import './RegistrationView.css';
import getEnvironement from '../../../../environement';

export default function RegistrationView({ entreprise }) {
  const Navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmerPassword, setconfirmerPassword] = useState('');
  const [errs, setErrs] = useState('');
  const handelName = (e) => {
    setName(e.target.value);
  };
  const handelEmail = (e) => {
    setEmail(e.target.value);
  };
  const handelPhone = (e) => {
    setPhone(e.target.value);
  };
  const handelPassword = (e) => {
    setPassword(e.target.value);
  };
  const handelconfirmerPassword = (e) => {
    setconfirmerPassword(e.target.value);
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    const [firstName, lastName] = name.split(' ');
    const user = {
      last_name: lastName, first_name: firstName, email, phone, password, confirmerPassword,
    };
    const url = `${getEnvironement().API_URL}/clients`;
    fetch(url, {
      method: 'post',

      headers: {
        'content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:3000/',
      },
      body: JSON.stringify(
        user,
      ),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.message) {
          Navigate('/auth/login?sendmail=rZgt9DFx2df');
        } else {
          setErrs(response.errors);
        }
      });
  };

  return (
    <div className={`registration-page ${entreprise ? 'signin-page-entreprise' : 'signin-page-individuel'}`}>
      <div className={`${entreprise ? 'bg-entreprise' : ''}`} />
      <NavBar transparent />
      <img src={LogoTransp} alt="" className="bg" />
      <div className="container-login">
        <div className="text">
          <span className="simple-text">SILYASSUR</span>
          <h3>
            <span className="simple-text bolder">
              <p>Bienvenue sur</p>
              <p>votre espace client</p>
              <p>Silyassur</p>
            </span>
          </h3>
          <span className="simple-text">Site également consultable sur mobile !</span>
        </div>
        <div className="wrapper-form">
          <p className="label-bienvenu">Bienvenu !</p>
          <p className="label">Se connecter</p>
          <form onSubmit={handelSubmit}>
            <div className="form-container-sing-up">
              <Input
                type="text"
                label="Email"
                placeholder="Entrer votre Email"
                fullwidth
                onChange={handelEmail}
              />
              {errs.email !== undefined && <p className="messageError">{errs.email}</p>}
              <Input
                type="text"
                label="Nom d'utilisateur"
                id="name"
                placeholder="Entrer votre nom d'utilisateur"
                fullwidth
                onChange={handelName}
              />
              {errs.first_name !== undefined && <p className="messageError">{errs.first_name}</p>}
              {errs.last_name !== undefined && <p className="messageError">{errs.last_name}</p>}
              <Input
                type="text"
                label="Numéro de téléphone"
                id="telephone"
                placeholder="Entrer votre numero de télephone"
                fullwidth
                onChange={handelPhone}
              />
              <Input
                type="password"
                label="Mot de passe "
                id="password"
                placeholder="Entrer votre mot de passe"
                fullwidth
                onChange={handelPassword}
              />
              {errs.password !== undefined && <p className="messageError">{errs.password}</p>}
              <Input
                type="password"
                label="Confirmer le mot de passe "
                id="c-password"
                placeholder="Confirmer votre mot de passe"
                fullwidth
                onChange={handelconfirmerPassword}
              />
              {errs.confirmerPassword !== undefined && <p className="messageError">{errs.confirmerPassword}</p>}
              <Button fullwidth>S&apos;inscrire</Button>
            </div>
          </form>
          <p>
            Vous avez déjà un compte?&nbsp;&nbsp;&nbsp;
            <Link to={entreprise ? '/auth/loginentreprise' : '/auth/login'}>Connexion</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
