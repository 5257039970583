const ImageAuto = require('../assets/images/bg-auto.png');
const ImageMoto = require('../assets/images/bg-moto.png');
const ImageSante = require('../assets/images/bg-sante.png');
const ImageVoyage = require('../assets/images/bg-voyage.png');
const ImageLoisir = require('../assets/images/bg-loisir.png');
const ImageEpargne = require('../assets/images/bg-epargne.png');
const ImageHabitation = require('../assets/images/bg-habitation.png');
const ImageAccident = require('../assets/images/bg-accident.png');
const ImageAssurMultiPro = require('../assets/images/bg-assurMultiPro.png');

const AssetsLogin = {
  AUTO: {
    image: ImageAuto,
    url: '/auto/form',
    title: 'Découvrez nos offres d\'assurance Automobile',
    text: 'Trouvez l’assurance Auto qui vous convient au meilleur prix !',
    type: 'AUTO',
  },
  MOTO: {
    image: ImageMoto,
    url: '/moto/form',
    title: 'Découvrez nos offres d\'assurance Automobile',
    text: 'Trouvez l’assurance Auto qui vous convient au meilleur prix !',
    type: 'MOTO',
  },
  SANTE: {
    image: ImageSante,
    url: '/sante/form',
    title: 'Prémunissez-vous et votre famille contre les aléas de la vie',
    text: 'Une offre d\'assurance complète de santé et de prévoyance.',
    type: 'santé',
  },
  VOYAGE: {
    image: ImageVoyage,
    url: '/voyage/form',
    title: 'Prémunissez-vous et votre famille contre les aléas de la vie',
    text: 'La protection incontournable contre les accidents de la vie !',
    type: 'VOYAGE',
  },
  LOISIR: {
    image: ImageLoisir,
    url: '/loisir/form',
    title: 'Adonnez-vous à vos loisirs en toute tranquillité',
    text: 'Vivez votre passion, nous assurons votre protection.',
    type: 'LOISIR',
  },
  EPARGNE: {
    image: ImageEpargne,
    url: '/epargne/form',
    title: 'Préparez votre retraite ou encore sécurisez l\'avenir financier de vos enfants',
    text: 'Un produit d\'épargne progressif en toute sécurité et à des conditions avantageuses.',
    type: 'EPARGNE',
  },
  HABITATION: {
    image: ImageHabitation,
    url: '/habitaion/form',
    title: 'Bénéficiez d’une couverture complète pour votre logement',
    text: 'Une assurance entièrement modulable qui répond à vos besoins et vous permet de bénéficier'
    + ' d’un large choix de garanties et de services tout en tenant compte du budget de chacun.',
    type: 'HABITATION',
  },
  ACCIDENT: {
    image: ImageAccident,
    url: '/accident/form',
    title: 'Soyez protégé en cas d\'accident corporel',
    text: 'La protection incontournable contre les accidents de la vie !',
    type: 'ACCIDENT',
  },
  ASSURMULTIPRO: {
    image: ImageAssurMultiPro,
    url: '/assurmultipro/form',
    title: 'Découvrez nos offres d\'assurance Assurance Multi Pro',
    text: 'Trouvez l’assurance assurance multi pro qui vous convient au meilleur prix !',
    type: 'ASSURMULTIPRO',
  },
  GESTIONFLOTTE: {
    image: ImageAssurMultiPro,
    url: '/gestionFlotte/form',
    title: 'Découvrez nos offres d\'assurance gestion du flotte',
    text: 'Trouvez l’assurance gestion du flotte qui vous convient au meilleur prix !',
    type: 'GESTIONFLOTTE',
  },
  GESTIONFLOTTECONNECTE: {
    image: ImageAssurMultiPro,
    url: '/gestionFlotteConnectee/form',
    title: 'Découvrez nos offres d\'assurance gestion de flotte connéctée',
    text: 'Trouvez l’assurance gestion de flotte connéctée qui vous convient au meilleur prix !',
    type: 'GESTIONFLOTTECONNECTEE',
  },
  TRANSPORTMARCHANDISE: {
    image: ImageAssurMultiPro,
    url: '/transportMarchandise/form',
    title: 'Découvrez nos offres d\'assurance Transport Marchandise',
    text: 'Trouvez l’assurance Transport de marchandise qui vous convient au meilleur prix !',
    type: 'TRANSPORTMARCHANDISE',
  },
  NAVIREPECHE: {
    image: ImageAssurMultiPro,
    url: '/navirePeche/form',
    title: 'Découvrez nos offres d\'assurance navire de pêche',
    text: 'Trouvez l’assurance NAVIREPECHE qui vous convient au meilleur prix !',
    type: 'NAVIREPECHE',
  },
  ASSURMOUKAWALATI: {
    image: ImageAssurMultiPro,
    url: '/assurMoukawalati/form',
    title: 'Découvrez nos offres d\'assurance moukawalati',
    text: 'Trouvez l’assurance Moukawalati qui vous convient au meilleur prix !',
    type: 'ASSURMOUKAWALATI',
  },
  AUTOSALARIE: {
    image: ImageAssurMultiPro,
    url: '/autoSalarie/form',
    title: 'Découvrez nos offres d\'assurance moukawalati',
    text: 'Trouvez l’assurance Moukawalati qui vous convient au meilleur prix !',
    type: 'AUTOSALARIE',
  },
  MOTOSALARIE: {
    image: ImageAssurMultiPro,
    url: '/motoSalarie/form',
    title: 'Découvrez nos offres d\'assurance moukawalati',
    text: 'Trouvez l’assurance Moukawalati qui vous convient au meilleur prix !',
    type: 'MOTOSALARIE',
  },
  ACCIDENTSALARIE: {
    image: ImageAssurMultiPro,
    url: '/assurMoukawalati/form',
    title: 'Découvrez nos offres d\'assurance moukawalati',
    text: 'Trouvez l’assurance Moukawalati qui vous convient au meilleur prix !',
    type: 'ACCIDENTSALARIE',
  },
  ABITATIONSALARIE: {
    image: ImageAssurMultiPro,
    url: '/abitationSalarie/form',
    title: 'Découvrez nos offres d\'assurance moukawalati',
    text: 'Trouvez l’assurance Moukawalati qui vous convient au meilleur prix !',
    type: 'ABITATIONSALARIE',
  },
  EPARGNESALARIE: {
    image: ImageAssurMultiPro,
    url: '/epargneSalarie/form',
    title: 'Découvrez nos offres d\'assurance moukawalati',
    text: 'Trouvez l’assurance Moukawalati qui vous convient au meilleur prix !',
    type: 'EPARGNESALARIE',
  },
  LOISIRSALARIE: {
    image: ImageAssurMultiPro,
    url: '/loisirSalarie/form',
    title: 'Découvrez nos offres d\'assurance loisir salarié',
    text: 'Trouvez l’assurance Loisir salarié qui vous convient au meilleur prix !',
    type: 'LOISIRSALARIE',
  },
  ACCIDENTCIVILE: {
    image: ImageAssurMultiPro,
    url: '/accidentCivile/form',
    title: 'Découvrez nos offres d\'assurance moukawalati',
    text: 'Trouvez l’assurance Moukawalati qui vous convient au meilleur prix !',
    type: 'ACCIDENTCIVILE',
  },
  ABITATIONCIVILE: {
    image: ImageAssurMultiPro,
    url: '/abitationCivile/form',
    title: 'Découvrez nos offres d\'assurance moukawalati',
    text: 'Trouvez l’assurance Moukawalati qui vous convient au meilleur prix !',
    type: 'ABITATIONCIVILE',
  },
  EPARGNECIVILE: {
    image: ImageAssurMultiPro,
    url: '/epargneCivile/form',
    title: 'Découvrez nos offres d\'assurance moukawalati',
    text: 'Trouvez l’assurance Moukawalati qui vous convient au meilleur prix !',
    type: 'EPARGNECIVILE',
  },
  LOISIRCIVILE: {
    image: ImageAssurMultiPro,
    url: '/loisirCivile/form',
    title: 'Découvrez nos offres d\'assurance loisir salarié',
    text: 'Trouvez l’assurance Loisir salarié qui vous convient au meilleur prix !',
    type: 'LOISIRCIVILE',
  },
  VOYAGECIVILE: {
    image: ImageAssurMultiPro,
    url: '/voyageCivile/form',
    title: 'Découvrez nos offres d\'assurance loisir salarié',
    text: 'Trouvez l’assurance Loisir salarié qui vous convient au meilleur prix !',
    type: 'VOYAGECIVILE',
  },
  SANTECIVILE: {
    image: ImageAssurMultiPro,
    url: '/santeCivile/form',
    title: 'Découvrez nos offres d\'assurance loisir salarié',
    text: 'Trouvez l’assurance Loisir salarié qui vous convient au meilleur prix !',
    type: 'VOYAGECIVILE',
  },
};
export default AssetsLogin;
