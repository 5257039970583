import React from 'react';
import Input from '../../../../components/inputs/Input/InputComponent';
import { ReactComponent as Upload } from '../../../../assets/images/upload.svg';

export default function Step2() {
  return (
    <div className="step-form">
      <div className="cart">
        <div className="wrapper-cart">
          <p className="text">la carte CNI recto</p>
          <div className="recto">
            <label htmlFor="cni-recto">
              <Upload />
              <div className="title1">
                Drag & drop files or
                <span className="blue">Browse</span>
              </div>
              <div className="title2">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</div>
              <input type="file" id="cni-recto" name="cni-recto" />
            </label>
          </div>
        </div>
        <div className="wrapper-cart">
          <p className="text">la carte CNI verso</p>
          <div className="verso">
            <label htmlFor="cni-recto">
              <Upload />
              <div className="title1">
                Drag & drop files or
                <span className="blue">Browse</span>
              </div>
              <div className="title2">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</div>
              <input type="file" id="cni-recto" name="cni-recto" />
            </label>
          </div>
        </div>
      </div>
      <div className="cart">
        <Input type="text" id="date" label="Date de début du contrat" />
        <Input type="text" id="date" label="Date de début du contrat" />
      </div>
      <div className="cart">
        <div className="wrapper-cart">
          <p className="text">la carte CNI recto</p>
          <div className="recto">
            <label htmlFor="cni-recto">
              <Upload />
              <div className="title1">
                Drag & drop files or
                <span className="blue">Browse</span>
              </div>
              <div className="title2">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</div>
              <input type="file" id="cni-recto" name="cni-recto" />
            </label>
          </div>
        </div>
        <div className="wrapper-cart">
          <p className="text">la carte CNI verso</p>
          <div className="verso">
            <label htmlFor="cni-recto">
              <Upload />
              <div className="title1">
                Drag & drop files or
                <span className="blue">Browse</span>
              </div>
              <div className="title2">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</div>
              <input type="file" id="cni-recto" name="cni-recto" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
