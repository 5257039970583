import React, { useRef } from 'react';
import { ReactComponent as Vector } from '../../assets/icons/vector.svg';
import Footer from '../Footer/FooterComponent';
import Women from '../../assets/icons/women.svg';
import './FooterFullStyles.scss';

export default function FooterFull() {
  const endOfPageRef = useRef(null);
  const vectorDownClick = () => {
    if (endOfPageRef.current) {
      endOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <section className="footer">
      <div className="avis-conatine">
        <div
          onClick={vectorDownClick}
          onKeyDown={vectorDownClick}
          role="button"
          tabIndex={0}
          className="vectorDown vector"
        >
          <Vector />
        </div>
        <div className="messages">
          <img src={Women} alt="" className="member" />
          <div className="msg-top msg">
            <div className="txt">
              Je voulais protéger ma société mais je ne
              comprenais rien aux assurances. Silyassur m&apos;a accompagnée
              de façon super efficace pour trouver
              les produits qui correspondaient
              le mieux à mon activité.
            </div>
            <div className="auther">
              <span className="name">Samantha Waman</span>
              Lorem ipsum
            </div>
          </div>
          <div className="msg-center msg">
            <div className="txt">
              Je voulais protéger ma société mais je ne
              comprenais rien aux assurances. Silyassur m&apos;a accompagnée
              de façon super efficace pour trouver
              les produits qui correspondaient
              le mieux à mon activité.
            </div>
            <div className="auther">
              <span className="name">Samantha Waman</span>
              <br />
              Lorem ipsum
            </div>
          </div>
          <div className="msg-bottom msg">
            <div className="txt">
              Je voulais protéger ma société mais je ne
              comprenais rien aux assurances. Silyassur m&apos;a accompagnée
              de façon super efficace pour trouver
              les produits qui correspondaient
              le mieux à mon activité.
            </div>
            <div className="auther">
              <span className="name">Samantha Waman</span>
              <br />
              Lorem ipsum
            </div>
          </div>
        </div>
        <div className="wrapper-text">
          <div className="title">
            L&apos;avis de nos clients
          </div>
          <div className="text">
            Nous plaçons la satisfaction client
            au centre de notre métier.
          </div>
        </div>
      </div>
      <div className="contact">
        <div className="wrapper">
          <div className="title">
            Parlez à un expert
            <br />
            <span className="sub-title">… pas à un chatbot</span>
          </div>
          <div className="text">
            Les nouvelles technologies nous aident à vous simplifier la vie,
            mais rassurez-vous, nous ne sommes pas des robots !
            <br />
            <span className="sub-text">Faites-vous rappeler par un expert.</span>
          </div>
          <div className="btn">Porgrammer un appel  </div>
        </div>
      </div>
      <Footer reff={endOfPageRef} />
    </section>
  );
}
