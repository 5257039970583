/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import axios from 'axios';
import './settingStyles.scss';
import { connect } from 'react-redux';
import Input from '../../../../components/inputs/Input/InputComponent';
import InputPassword from '../../../../components/inputs/LoginInput/LoginInputComponent';
import Button from '../../../../components/buttons/Button/ButtonComponent';
import setCurrentUser from '../../../../../db/redux/user/user-actions';
import getEnvironement from '../../../../../../environement';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Setting({ currentUser, SetcurrentUser }) {
  const [firstName, setFirstName] = useState(currentUser.first_name);
  const [lastName, setLastName] = useState(currentUser.last_name);
  const [email, setEmail] = useState(currentUser.email);
  const [phone, setPhone] = useState(currentUser.phone);
  const [password, setPassword] = useState('');
  const [confirmerPassword, setconfirmerPassword] = useState('');

  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setSelectedImage(reader.result);
        /*
        const url = `${getEnvironement().API_URL}/clt/getImgProfile`;
        const body = { user_id: currentUser.id };
        axios.post(url, body).then((response) => {
          SetcurrentUser({ ...currentUser, profile_image_filename: response.data.imgProfile });
        });
        */
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handelFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const handelLastName = (e) => {
    setLastName(e.target.value);
  };
  const handelEmail = (e) => {
    setEmail(e.target.value);
  };
  const handelPhone = (e) => {
    setPhone(e.target.value);
  };
  const handelPassword = (e) => {
    setPassword(e.target.value);
  };
  const handelSetPassword = (e) => {
    setconfirmerPassword(e.target.value);
  };
  const handelSubmit = async (e) => {
    e.preventDefault();
    const user = {
      last_name: lastName, first_name: firstName, email, phone, password, imgProfile: selectedImage,
    };

    fetch(`${getEnvironement().API_URL}/clients/${currentUser.id}`, {
      method: 'put',

      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify(
        user,
      ),
    })
      .then((response) => response.json())
      .then((response) => {
        SetcurrentUser({ ...currentUser, ...response.user });
        setPassword('');
        setconfirmerPassword('');
        toast.success('Félicitations, votre modification a été bien efectuer !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };
  const urlImagePrefix = `${getEnvironement().BACKEND_URL}/uploads/images/users/`;
  const [selectedImage, setSelectedImage] = useState(urlImagePrefix + currentUser.profile_image_filename);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="tabs container" sx={{ width: '100%' }}>
      <Box>
        <Tabs className="tabs1 container1" value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Profil" {...a11yProps(0)} className="tab option" />
          <Tab label="Securité" {...a11yProps(1)} className="tab option" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <form onSubmit={handelSubmit} className="form-setting">
          <div className="bar">
            <div className="info">
              <div>
                <input
                  type="file"
                  name="image-profile"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept="image/*"
                />
                <div
                  role="button"
                  tabIndex={0}
                  onClick={handleImageClick}
                  onKeyDown={handleImageClick}
                  style={{ cursor: 'pointer', minHeight: '50px', minWidth: '100px' }}
                >
                  <img
                    src={selectedImage}
                    alt=""
                  />
                </div>
              </div>
              <div className="name-email">
                <div className="name">
                  {`${currentUser.first_name} ${currentUser.last_name}`}

                </div>
                <div className="email">
                  {currentUser.email}
                </div>
              </div>
            </div>
            <div className="btns">
              <Button reverse> Annuler </Button>
              <Button> Enregistrer</Button>
            </div>
          </div>
          <div className="groupe">
            <Input type="text" label="Prenom" placeholder="Prenom" fullwidth value={firstName} onChange={handelFirstName} />
            <Input type="text" label="Nom" placeholder="Nom" fullwidth value={lastName} onChange={handelLastName} />
          </div>
          <div className="groupe">
            <Input type="text" label="Email" placeholder="Email" fullwidth value={email} onChange={handelEmail} />
            <Input type="text" label="Téléphone" placeholder="Téléphone" fullwidth value={phone} onChange={handelPhone} />
          </div>
        </form>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <form onSubmit={handelSubmit} className="form-setting">
          <div className="bar">
            <div className="info">
              <img
                src={`${urlImagePrefix + currentUser.profile_image_filename}`}
                alt=""
              />
              <div className="name-email">
                <div className="name">
                  {`${currentUser.first_name} ${currentUser.last_name}`}

                </div>
                <div className="email">
                  {currentUser.email}
                </div>
              </div>
            </div>
            <div className="btns">
              <Button reverse> Annuler </Button>
              <Button> Enregistrer</Button>
            </div>
          </div>
          <div className="groupe">
            <InputPassword
              type="password"
              label="Mot de passe"
              placeholder="Mot de passe"
              fullwidth
              value={password}
              onChange={handelPassword}
            />
            <InputPassword
              type="password"
              label="Confirmation Mot de passe"
              placeholder="Confirmation Mot de passe"
              fullwidth
              value={confirmerPassword}
              onChange={handelSetPassword}
            />
          </div>
        </form>
      </TabPanel>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
}
const mapDispatchToProps = (dispatch) => ({
  SetcurrentUser: (user) => dispatch(setCurrentUser(user)),
});

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Setting);
