import React, { useState } from 'react';
import './boxStyles.scss';
import { Button } from '@mui/material';
import axios from 'axios';
import AddNewPaymentDialog from '../dialog/addPaymentDialog';
import getEnvironement from '../../../../../../../environement';
import SimpleBackdrop from '../../../../../components/backDrop/backDrop';

function Box({
  title, subTitle, header, body, callMessage, total, listAssurances, CurrentUserId, SetcurrentUser,
}) {
  const [openAddNewPaymentDialog, setOpenAddNewPaymentDialog] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [error, setError] = useState(null);

  const saveCurrentUser = async () => {
    const url = `${getEnvironement().API_URL}/clients/currentUser`;
    fetch(url, {
      method: 'post',

      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify(
        {
          userId: CurrentUserId,
        },
      ),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.message) {
          const { user } = response;
          user.policies = response.policies;
          user.payments = response.payments;
          user.attachments = response.attachments;
          SetcurrentUser(user);
        }
        return response;
      });
  };

  const handleClosePayementDialog = () => { setOpenAddNewPaymentDialog(false); };
  const handleClickPayementDialog = (assurance, montant, date, methode, userId) => {
    setOpenBackdrop(true);
    const url = `${getEnvironement().API_URL}/clients/addPayment`;
    const payment = {
      assurance,
      montant,
      date,
      methode,
      user_id: userId,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    axios.post(url, payment, config)
      .then(() => saveCurrentUser())
      .then(() => setOpenBackdrop(false))
      .then(() => setOpenAddNewPaymentDialog(false))
      .catch((err) => {
        if (err.response.data.errors) {
          setError('merci de remplir correctement le formulaire, les champs ne doivent pas être null');
          setOpenBackdrop(false);
        }
      });
  };
  return (
    <div className="box-profile">
      <SimpleBackdrop open={openBackdrop} />
      <AddNewPaymentDialog
        open={openAddNewPaymentDialog}
        handleClose={handleClosePayementDialog}
        handleClick={handleClickPayementDialog}
        listAssurances={listAssurances}
        userId={CurrentUserId}
        error={error}
      />
      <div className="header">
        <div className="title">
          {' '}
          {title}
        </div>
        <Button
          variant="contained"
          onClick={() => {
            setError(null);
            setOpenAddNewPaymentDialog(true);
          }}
        >
          {subTitle}
        </Button>
      </div>
      <br />
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {
                header.map((thE) => (<th key={thE}>{thE}</th>))
              }
            </tr>
          </thead>
          <tbody>

            {
              body.length > 0
                ? body.map((payment) => (
                  <tr key={payment.id}>
                    <td>{payment.id}</td>
                    <td>{payment.policy.ref_no}</td>
                    <td>{payment.amount}</td>
                    <td>{payment.date}</td>
                    <td>{payment.method}</td>
                  </tr>
                ))
                : (
                  <tr>
                    <td colSpan={header.length}>
                      {callMessage}
                      {' '}
                    </td>
                  </tr>
                )
            }

            {
              total && (
                <tr>
                  <td className="text-start" colSpan={3}>total</td>

                  <td className="text-start">Tko</td>
                  <td className="text-start" colSpan={5}>Tko</td>

                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Box;
