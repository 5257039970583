import React from 'react';
import './InputStyles.scss';

export default function customeInput({
  label,
  id,
  fullwidth,
  required,
  style,
  type,
  placeholder,
  onChange,
}) {
  return (
    <div style={style} className={`field-2 ${fullwidth ? 'full-width' : ''} ${required ? 'required' : ''}`}>
      <label htmlFor={id}>{label}</label>
      <input
        required={required}
        id={id}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
}
