import React from 'react';
import './InputStyles.scss';

export default function customeInput({
  label,
  id,
  name,
  fullwidth,
  required,
  style,
  type,
  placeholder,
  onChange,
  onBlur,
  value,
  disabled = false,
}) {
  return (
    <div style={style} className={`field-1 ${fullwidth ? 'full-width' : ''} ${required ? 'required' : ''}`}>
      <label htmlFor={id}>{label}</label>
      <input
        required={required}
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        className={disabled ? 'disabled' : ''}
      />
    </div>
  );
}
