import React from 'react';
import Input from '../../../../components/inputs/Input/InputComponent';

export default function step1() {
  return (
    <div className="step-form">
      <Input type="text" id="date" placeholder="nom" fullwidth />
      <Input type="text" id="date" placeholder="prenom" fullwidth />
      <Input type="text" id="date" placeholder="Email" fullwidth />
      <Input type="text" id="date" placeholder="Telephone" fullwidth />
      <Input type="text" id="date" placeholder="Type de loisir" fullwidth />
    </div>
  );
}
