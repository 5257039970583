import React, { useState } from 'react';
import './StepsStyles.scss';
import Input from '../../../../components/inputs/Input/InputComponent';
import { ReactComponent as Upload } from '../../../../assets/images/upload.svg';

export default function Step3() {
  const [CinRecto, setCinRecto] = useState('');
  const [CinVerso, setCinVerso] = useState('');
  const [permiRecto, setpermiRecto] = useState('');
  const [permiVerso, setpermiVerso] = useState('');
  const onInputFileChange = (e) => {
    switch (e.target.id) {
    case 'cni-recto':
      setCinRecto(e.target.files[0].name);
      break;
    case 'cni-verso':
      setCinVerso(e.target.files[0].name);
      break;
    case 'permi-recto':
      setpermiRecto(e.target.files[0].name);
      break;
    case 'permi-verso':
      setpermiVerso(e.target.files[0].name);
      break;
    default:
      break;
    }
  };
  return (
    <div className="step-form">
      <div className="cart">
        <div className="wrapper-cart">
          <p className="text">la carte CNI recto</p>
          <div className="recto">
            {CinRecto !== ''
              ? (
                <div className="inputFileExist">
                  <input
                    type="file"
                    className="fileExist"
                    id="cni-recto"
                    name="cni-recto"
                    onChange={(e) => onInputFileChange(e)}
                  />
                  {CinRecto}
                </div>
              )
              : (
                <label htmlFor="cni-recto">
                  <Upload />
                  <div className="title1">
                    Drag & drop files or
                    <span className="blue"> Browse</span>
                  </div>
                  <div className="title2">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</div>
                  <input type="file" id="cni-recto" name="cni-recto" onChange={(e) => onInputFileChange(e)} />
                </label>
              )}
          </div>
        </div>
        <div className="wrapper-cart">
          <p className="text">la carte CNI verso</p>
          <div className="verso">
            {CinVerso !== ''
              ? (
                <div className="inputFileExist">
                  <input
                    type="file"
                    className="fileExist"
                    id="cni-verso"
                    name="cni-verso"
                    onChange={(e) => onInputFileChange(e)}
                  />
                  {CinVerso}
                </div>
              )
              : (
                <label htmlFor="cni-verso">
                  <Upload />
                  <div className="title1">
                    Drag & drop files or
                    <span className="blue"> Browse</span>
                  </div>
                  <div className="title2">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</div>
                  <input type="file" id="cni-verso" name="cni-verso" onChange={(e) => onInputFileChange(e)} />
                </label>
              )}
          </div>
        </div>
      </div>
      <div className="cart">
        <Input type="date" id="date" label="Date de début du contrat" className="disabled" disabled />
        <Input type="date" id="date" label="Date de fin du contrat" className="disabled" disabled />
      </div>
      <div className="cart">
        <div className="wrapper-cart">
          <p className="text">Permi recto</p>
          <div className="recto">
            {permiRecto !== ''
              ? (
                <div className="inputFileExist">
                  <input
                    type="file"
                    className="fileExist"
                    id="permi-recto"
                    name="permi-recto"
                    onChange={(e) => onInputFileChange(e)}
                  />
                  {permiRecto}
                </div>
              )
              : (
                <label htmlFor="permi-recto">
                  <Upload />
                  <div className="title1">
                    Drag & drop files or
                    <span className="blue"> Browse</span>
                  </div>
                  <div className="title2">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</div>
                  <input type="file" id="permi-recto" name="permi-recto" onChange={(e) => onInputFileChange(e)} />
                </label>
              )}
          </div>
        </div>
        <div className="wrapper-cart">
          <p className="text">permi verso</p>
          <div className="verso">
            {permiVerso !== ''
              ? (
                <div className="inputFileExist">
                  <input
                    type="file"
                    className="fileExist"
                    id="permi-verso"
                    name="permi-verso"
                    onChange={(e) => onInputFileChange(e)}
                  />
                  {permiVerso}
                </div>
              )
              : (
                <label htmlFor="permi-verso">
                  <Upload />
                  <div className="title1">
                    Drag & drop files or
                    <span className="blue"> Browse</span>
                  </div>
                  <div className="title2">Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT</div>
                  <input type="file" id="permi-verso" name="permi-verso" onChange={(e) => onInputFileChange(e)} />
                </label>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
