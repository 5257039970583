import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './LoginStyles.scss';
import Close from '@mui/icons-material/Close';
import ReportProblem from '@mui/icons-material/ReportProblem';
import { connect } from 'react-redux';
import { Alert } from '@mui/material';
import Button from '../../components/buttons/Button/ButtonComponent';
import Input from '../../components/inputs/LoginInput/LoginInputComponent';
import NavBar from '../../components/NavBar/NavBarComponent';
import LogoTransp from '../../assets/images/logo-s-transparent.png';
import setCurrentUser from '../../../db/redux/user/user-actions';
import getEnvironement from '../../../../environement';

function LoginView({ setcurrentUser, entreprise }) {
  const Navigate = useNavigate();
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handelEmail = (e) => {
    setEmail(e.target.value);
  };

  const handelPassword = (e) => {
    setPassword(e.target.value);
  };
  const handelSubmit = async (e) => {
    setError(false);

    e.preventDefault();
    const body = {
      email,
      password,
    };
    const url = `${getEnvironement().API_URL}/clients/login`;
    fetch(url, {
      method: 'post',

      headers: {
        'content-Type': 'application/json',
      },
      body: JSON.stringify(
        body,
      ),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message) {
          setError(true);
        } else {
          const { user } = response;
          user.policies = response.policies;
          user.payments = response.payments;
          user.attachments = response.attachments;
          setcurrentUser(user);
          Navigate('/');
        }
        return response;
      });
  };

  useEffect(() => {
    if (success() || erreur() || sendmail() || resetpass() || successpass()) {
      setcurrentUser(null);
      if (entreprise) {
        setTimeout(() => { Navigate('/auth/loginentreprise'); }, 6000);
      } else {
        setTimeout(() => { Navigate('/auth/login'); }, 6000);
      }
    }
  });

  const success = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const valide = searchParams.get('success');
    if (valide) {
      return valide;
    }
    return false;
  };

  const successpass = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const validepass = searchParams.get('successpass');
    if (validepass) {
      return validepass;
    }
    return false;
  };

  const erreur = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const pasValide = searchParams.get('error');
    if (pasValide) {
      return pasValide;
    }
    return false;
  };

  const sendmail = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const sendValide = searchParams.get('sendmail');
    if (sendValide) {
      return sendValide;
    }
    return false;
  };

  const resetpass = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const sendresetPass = searchParams.get('resetpass');
    if (sendresetPass) {
      return sendresetPass;
    }
    return false;
  };

  return (
    <div className={`signin-page ${entreprise ? 'signin-page-entreprise' : 'signin-page-individuel'}`}>
      <div className={`${entreprise ? 'bg-entreprise' : ''}`} />
      <NavBar transparent />
      <img src={LogoTransp} alt="" className="bg" />
      <div className="container-login">
        <div className="text">
          <span className="simple-text">SILYASSUR</span>
          <h3>
            <span className="simple-text bolder">
              <p>Bienvenue sur</p>
              <p>votre espace client</p>
              <p>Silyassur</p>
            </span>
          </h3>
          <span className="simple-text">Site également consultable sur mobile !</span>
        </div>
        <div className="wrapper-form">
          {success() && <Alert severity="success">votre email a bien été validé</Alert>}
          {successpass() && <Alert severity="success">votre email a bien été réinitialisé</Alert>}
          {erreur() && <Alert severity="error">ce lien ne semble plus valide</Alert>}
          {sendmail() && <Alert severity="info">un email vous a été envoyé pour validation</Alert>}
          {resetpass() && <Alert severity="info">un email vous a été envoyé pour réinitialisation du mot de passe</Alert>}
          <p>Se connecter</p>
          {!error ? null : (
            <div className="error-message">
              <Close className="close" onClick={() => setError(false)} />
              <ReportProblem className="alter-icon" />
              <div className="content">
                <div className="header">
                  nom d&apos;utilisateur ou mot de passe invalide
                </div>
              </div>
            </div>
          )}
          <form onSubmit={handelSubmit}>
            <div className="form-container-sing-in">
              <Input
                type="text"
                placeholder="Email"
                fullwidth
                useIcon
                onChange={handelEmail}
              />
              <Input
                type="password"
                id="password"
                placeholder="Mot de Passe"
                fullwidth
                useIcon
                onChange={handelPassword}
              />
              <div className="options">
                <div className="checkbox">
                  <label htmlFor="remember">
                    <input type="checkbox" id="remember" name="remember" />
                    &nbsp;Mémoriser mon identifiant
                  </label>
                </div>
                <div className="text-right">
                  <Link
                    to={entreprise ? '/forgotpasswordentreprise' : '/forgotpassword'}
                    className="forgot auth-action"
                    action="forgot-password"
                  >
                    Mot de passe oublie ?
                  </Link>
                </div>
              </div>
              <Button style={{ position: 'relative', top: '15px' }} fullwidth>Se connecter</Button>
            </div>
          </form>
          <p className="link">
            Première connexion ?&nbsp;&nbsp;
            <Link className="cliquez-ici" to={entreprise ? '/registrationentreprise' : '/registration'}>Cliquez ici</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setcurrentUser: (user) => dispatch(setCurrentUser(user)),
});

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginView);
